import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../common/Button';

const HeroSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '85vh',
        backgroundImage: `
          linear-gradient(to right, rgba(0, 0, 0, 0.6), transparent),
          url("/images/landing-page/male_surfers.jpeg")
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        px: { xs: 2, sm: 4, md: 20 },
        py: { xs: 4, sm: 6, md: 10 },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '600px',
          ml: { xs: 'auto', sm: 0, md: 0, lg: 5, xl: 20 },
          mt: { xs: 2, sm: 6, md: 10, lg: 10, xl: 40 },
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' },
            fontFamily: 'CustomSurfFont, Arial, sans-serif',
          }}
        >
          Level Trevel
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: '500',
            letterSpacing: '2px',
            textTransform: 'uppercase',
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem' },
            display: 'inline-block',
            position: 'relative',
            fontFamily: 'CustomSurfFont, Arial, sans-serif',
          }}
        >
          {t('your_surf_adventure_awaits')}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
            fontSize: { xs: '0.9rem', sm: '1rem' },
            fontFamily: 'CustomSurfFont, Arial, sans-serif',
          }}
        >
          {t('discover_best_surf_spots')}
        </Typography>

        <Box mt={4}>
          <CustomButton
            text={t('join_now')}
            href="/auth"
            sx={{ mr: 2, mb: 2, minWidth: '250px', px: 4, py: 1.5, borderRadius: '24px' }}
          />
          <CustomButton
            text={t('loyalty_program')}
            href="/loyalty"
            variant="outlined"
            sx={{
              mr: 2,
              mb: 2,
              minWidth: '250px',
              color: '#fff',
              borderColor: '#fff',
              borderRadius: '24px',
              px: 4,
              py: 1.5,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
