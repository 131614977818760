import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, Alert, Box, Container } from '@mui/material';
import { MeQuery, MeQueryVariables, MembershipStatusEnum, User } from '../../generated/graphql';
import { ME_QUERY } from '../../graphql/users';
import { useNavigate } from 'react-router-dom';
import ProfileDashboard from '../../components/Memberships/ActiveMembershipDashboard/ProfileDashboard';

const MembershipDashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<MeQuery, MeQueryVariables>(ME_QUERY, {
    fetchPolicy: 'network-only',
  });

  // Add initial load tracking
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  // Modified useEffect to only run on initial load
  useEffect(() => {
    if (!loading && data?.me && !initialCheckDone) {
      const membership = data.me.membership;
      if (
        !membership ||
        membership.status === MembershipStatusEnum.Initiated ||
        membership.status === MembershipStatusEnum.Pending ||
        membership.status === MembershipStatusEnum.Rejected ||
        membership.status === MembershipStatusEnum.Inactive
      ) {
        navigate('/membership-request');
      }
      setInitialCheckDone(true);
    }
  }, [loading, data, navigate, initialCheckDone]);

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  // If we get here, membership should be ACTIVE
  const user = data?.me as User | null;
  if (!user) {
    return <Alert severity="error">User data not available.</Alert>;
  }

  // Show the membership dashboard with tabs, etc.
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <ProfileDashboard user={user} />
    </Container>
  );
};

export default MembershipDashboardPage;
