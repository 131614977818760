import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  TextField,
  Typography,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  SnackbarCloseReason,
  IconButton,
  CircularProgress,
  Divider,
  Grid2,
} from '@mui/material';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { CREATE_TRIP_MUTATION, TRIP_QUERY, UPDATE_TRIP_MUTATION } from '../../graphql/trips';
import {
  ContinentEnum,
  CreateDayItineraryDto,
  CreateExtraInfoDto,
  CreateTripDto,
  DayItinerary,
  ExtraInfo,
  Trip,
  User,
} from '../../generated/graphql';

import EditableAccordion from './EditableAccordion';
import RichTextEditor from '../common/RichTextEditor/RichTextEditor';
import Itinerary from './EditTripComponents/Itinerary';
import ImageUploadTrip from '../ImageUploadTrip/ImageUploadTrip';
import ExtraInfoComponent from './EditTripComponents/ExtraInfo';
import GuidesFilter from './EditTripComponents/GuidesFilter';

import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import countryList from 'country-list';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface EditTripFormProps {
  trip: Trip | CreateTripDto;
  isCreatingTrip: boolean;
}

const DEFAULT_CANCELLATION_POLICY = {
  baseFee: 50,
  currency: 'EUR',
  note: 'In some specific cases, such as serious illness or unexpected life events, we reserve the right to grant an exception and reconsider the cancellation policy. Please contact us so that we can review your specific case.',
  rules: [
    { daysBeforeStart: 30, refundPercentage: 100 },
    { daysBeforeStart: 0, refundPercentage: 0 },
  ],
} as const;

const EditTripForm: React.FC<EditTripFormProps> = ({ trip, isCreatingTrip }) => {
  const { t } = useTranslation();

  // Step 1: Create a list of countries for the AutoComplete
  const countryOptions = countryList.getData().map((c) => ({
    code: c.code,
    label: c.name,
  }));

  const validationSchema = Yup.object().shape({
    continent: Yup.string()
      .oneOf(Object.values(ContinentEnum), t('validationContinentOneOf'))
      .required(t('validationContinentReq')),
    country: Yup.string().required(t('validationCountry')),
    description: Yup.string().required(t('validationDescription')),
    endDate: Yup.date().required(t('validationEndDateReq')).min(Yup.ref('startDate'), t('validationEndDateMin')),
    excludedServices: Yup.array().min(1, t('validationExcludedServices')),
    extraInformation: Yup.array().min(1, t('validationExtraInformation')).required(t('validationExtraInformation')),
    guidesIds: Yup.array().min(1, t('validationGuidesIds')).required(t('validationGuidesIds')),
    images: Yup.array().min(4, t('validationImages')),
    includedServices: Yup.array().min(1, t('validationIncludedServices')),
    itinerary: Yup.array().min(1, t('validationItinerary')).required(t('validationItinerary')),
    numberOfPeople: Yup.number().min(1, t('validationNumberOfPeopleMin')).required(t('validationNumberOfPeopleReq')),
    price: Yup.number().min(0, t('validationPriceMin')).required(t('validationPriceReq')),
    startDate: Yup.date().required(t('validationStartDate')),
    temporaryBookingLink: Yup.string().url(t('validationTemporaryBookingLink')).optional(),
    title: Yup.string().required(t('validationTitle')),
    cancellationPolicy: Yup.object().shape({
      baseFee: Yup.number().min(0, 'Base fee cannot be negative').nullable(),
      currency: Yup.string().nullable(),
      note: Yup.string().nullable(),
      rules: Yup.array()
        .of(
          Yup.object().shape({
            daysBeforeStart: Yup.number().min(0, 'Days before start must be >= 0').required('Required'),
            refundPercentage: Yup.number()
              .min(0, 'Refund cannot be negative')
              .max(100, 'Cannot exceed 100%')
              .required('Required'),
          })
        )
        .nullable(),
    }),
  });

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const displayErrorsInSnackbar = (errors: any) => {
    const errorMessages = Object.values(errors);
    if (errorMessages.length > 0) {
      setSnackbarMessage(errorMessages[0] as string);
      setSnackbarOpen(true);
    }
  };

  const [updateTrip, { loading }] = useMutation(UPDATE_TRIP_MUTATION, {
    refetchQueries: (trip as Trip)?.id
      ? [
          {
            query: TRIP_QUERY,
            variables: { id: (trip as Trip).id },
          },
        ]
      : [],
  });
  const [createTrip] = useMutation(CREATE_TRIP_MUTATION);

  const [imageUrls, setImageUrls] = useState<string[]>(trip.images || []);

  const navigate = useNavigate();

  // Helper to update an existing trip
  const handleUpdate = async (values: any) => {
    for (let day of values.itinerary) {
      delete day['__typename'];
    }
    for (let item of values.extraInformation) {
      delete item['__typename'];
      delete item['id'];
    }
    delete values.guides;

    try {
      await updateTrip({
        variables: {
          id: (trip as Trip).id,
          data: {
            ...values,
            images: imageUrls,
            startDate: values.startDate ? values.startDate.toFormat('yyyy-MM-dd') : null,
            endDate: values.endDate ? values.endDate.toFormat('yyyy-MM-dd') : null,
          },
        },
      });
      navigate(`/trip/${(trip as Trip).id}`, { replace: true });
    } catch (err: any) {
      console.error('Error updating trip', err);
    }
  };

  //  create a new trip
  const handleCreate = async (values: any) => {
    try {
      delete values.guides;
      const response = await createTrip({
        variables: {
          input: {
            ...values,
            images: imageUrls,
            startDate: values.startDate ? values.startDate.toFormat('yyyy-MM-dd') : null,
            endDate: values.endDate ? values.endDate.toFormat('yyyy-MM-dd') : null,
          },
        },
      });

      const tripId = response.data.createTrip.id;
      navigate(`/trip/${tripId}`, { replace: true });
    } catch (err: any) {
      console.error('Error creating trip', err);
    }
  };

  const handleSnackbarClose = (_event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const validInnerObjects = (values: any): boolean => {
    // check Extra Info
    for (const info of values.extraInformation as CreateExtraInfoDto[]) {
      if (!info.title.trim()) {
        setSnackbarMessage(t('validationExtraInfoTitle'));
        setSnackbarOpen(true);
        return false;
      } else if (!info.description.trim()) {
        setSnackbarMessage(t('validationExtraInfoDesc'));
        setSnackbarOpen(true);
        return false;
      }
    }

    // check Itinerary
    for (const item of values.itinerary as CreateDayItineraryDto[]) {
      if (!item.description.trim()) {
        setSnackbarMessage(t('validationItineraryDesc'));
        setSnackbarOpen(true);
        return false;
      }
    }
    return true;
  };

  const buttonString = isCreatingTrip ? 'Create Trip' : 'Update Trip';

  return (
    <Formik
      initialValues={{
        title: trip.title || '',
        description: trip.description || '',
        temporaryBookingLink: trip.temporaryBookingLink || '',
        startDate: trip.startDate ? DateTime.fromISO(trip.startDate) : null,
        endDate: trip.endDate ? DateTime.fromISO(trip.endDate) : null,
        numberOfPeople: trip.numberOfPeople || 1,
        price: trip.price || 0,
        country: trip.country || '',
        images: trip.images || [],
        includedServices: trip.includedServices || [],
        excludedServices: trip.excludedServices || [],
        continent: trip.continent || ContinentEnum.Africa,
        itinerary: trip.itinerary || [{ description: '', photoUrls: [] }],
        extraInformation: trip.extraInformation || [{ title: '', description: '' }],
        guides: (trip as Trip)?.guides || [],
        guidesIds: (trip as Trip)?.guides?.map((u) => u.id) || [],
        cancellationPolicy: trip.cancellationPolicy
          ? {
              baseFee: trip.cancellationPolicy.baseFee ?? DEFAULT_CANCELLATION_POLICY.baseFee,
              currency: trip.cancellationPolicy.currency ?? DEFAULT_CANCELLATION_POLICY.currency,
              note: trip.cancellationPolicy.note ?? DEFAULT_CANCELLATION_POLICY.note,
              rules:
                trip.cancellationPolicy.rules?.map((r) => ({
                  daysBeforeStart: r.daysBeforeStart,
                  refundPercentage: r.refundPercentage,
                })) || DEFAULT_CANCELLATION_POLICY.rules,
            }
          : DEFAULT_CANCELLATION_POLICY,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        if (!validInnerObjects(values)) {
          setSubmitting(false);
          setStatus({ success: false, message: 'Invalid trip object' });
          return;
        }

        if (isCreatingTrip) {
          handleCreate(values)
            .then(() => {
              setSubmitting(false);
              setStatus({ success: true });
            })
            .catch((error) => {
              setSubmitting(false);
              setStatus({ success: false, message: error.message });
            });
        } else {
          handleUpdate(values)
            .then(() => {
              setSubmitting(false);
              setStatus({ success: true });
            })
            .catch((error) => {
              setSubmitting(false);
              setStatus({ success: false, message: error.message });
            });
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue, isSubmitting, status }) => {
        const handleDeleteImage = async (img: string) => {
          const accessToken = localStorage.getItem('accessToken');
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/upload/image`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({ imageUrl: img }),
            });
            if (!response.ok) {
              throw new Error('Failed to delete image');
            }
            // update local state
            const updated = imageUrls.filter((url) => url !== img);
            setImageUrls(updated);
            setFieldValue('images', updated);
          } catch (err) {
            console.error(err);
          }
        };

        const handleChangeStartDate = (date: DateTime | null) => {
          setFieldValue('startDate', date);
          if (values.endDate && date && date > values.endDate) {
            setFieldValue('endDate', null);
          }
        };

        const handleChangeDesc = (content: string) => {
          setFieldValue('description', content);
        };

        const handleItinerary = (itinerary: (DayItinerary | CreateDayItineraryDto)[]) => {
          setFieldValue('itinerary', itinerary);
        };

        const handleExtraInfo = (extraInformation: (ExtraInfo | CreateExtraInfoDto)[]) => {
          setFieldValue('extraInformation', extraInformation);
        };

        const handleGuidesFilter = (guides: User[]) => {
          const guidesIds = guides.map((u) => u.id);
          setFieldValue('guides', guides);
          setFieldValue('guidesIds', guidesIds);
        };

        const handleAddImageUrl = (newUrl: string) => {
          setImageUrls((prev) => {
            const newArr = [...prev, newUrl];
            setFieldValue('images', newArr);
            return newArr;
          });
        };

        const action = (
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        );

        return (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (Object.keys(errors).length > 0) {
                displayErrorsInSnackbar(errors);
              } else {
                handleSubmit(e);
              }
            }}
          >
            <Grid2 container spacing={2} sx={{ mt: 1 }}>
              {/* Title */}
              <Grid2 size={{ xs: 12 }}>
                <TextField
                  label="Trip Title"
                  name="title"
                  fullWidth
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid2>

              {/* Description (rich text) */}
              <Grid2 size={{ xs: 12 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Description
                </Typography>
                <RichTextEditor content={values.description} onChange={handleChangeDesc} />
              </Grid2>

              {/* Temp booking link */}
              <Grid2 size={{ xs: 12 }}>
                <TextField
                  label="Temporary Booking Link"
                  name="temporaryBookingLink"
                  fullWidth
                  value={values.temporaryBookingLink}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.temporaryBookingLink && Boolean(errors.temporaryBookingLink)}
                  helperText={touched.temporaryBookingLink && errors.temporaryBookingLink}
                />
              </Grid2>

              {/* Start date */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    label="Start Date"
                    value={values.startDate}
                    onChange={handleChangeStartDate}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
                {touched.startDate && errors.startDate && (
                  <Typography variant="caption" color="error">
                    {errors.startDate as string}
                  </Typography>
                )}
              </Grid2>

              {/* End date */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    label="End Date"
                    value={values.endDate}
                    onChange={(d: DateTime | null) => {
                      setFieldValue('endDate', d);
                    }}
                    minDate={values.startDate ? values.startDate : undefined}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
                {touched.endDate && errors.endDate && (
                  <Typography variant="caption" color="error">
                    {errors.endDate as string}
                  </Typography>
                )}
              </Grid2>

              {/* Number of people */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <TextField
                  label="Number of People"
                  name="numberOfPeople"
                  type="number"
                  fullWidth
                  value={values.numberOfPeople}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.numberOfPeople && Boolean(errors.numberOfPeople)}
                  helperText={touched.numberOfPeople && errors.numberOfPeople}
                />
              </Grid2>

              {/* Price */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <TextField
                  label="Price (€)"
                  name="price"
                  type="number"
                  fullWidth
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                  InputProps={{ inputProps: { min: 0, step: '0.01' } }}
                />
              </Grid2>

              {/* Country (AutoComplete) */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Autocomplete
                  fullWidth
                  id="country-autocomplete"
                  options={countryOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    // find object in array that matches current values.country
                    countryOptions.find((c) => c.code === values.country) || null
                  }
                  onChange={(event, newValue) => {
                    // If user clears or picks a new country
                    setFieldValue('country', newValue ? newValue.code : '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      name="country"
                      error={touched.country && Boolean(errors.country)}
                      helperText={touched.country && errors.country}
                    />
                  )}
                />
              </Grid2>

              {/* Continent */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth error={touched.continent && Boolean(errors.continent)}>
                  <InputLabel id="continent-label">Continent</InputLabel>
                  <Select
                    labelId="continent-label"
                    label="Continent"
                    name="continent"
                    value={values.continent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {Object.values(ContinentEnum).map((continent) => (
                      <MenuItem key={continent} value={continent}>
                        {continent}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.continent && errors.continent && (
                    <Typography variant="caption" color="error">
                      {errors.continent as string}
                    </Typography>
                  )}
                </FormControl>
              </Grid2>

              {/* Guides */}
              <Grid2 size={{ xs: 12 }}>
                <GuidesFilter onChange={handleGuidesFilter} guides={values.guides} />
              </Grid2>

              {/* Image upload */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Trip Photos
                </Typography>
                <ImageUploadTrip onUploadSuccess={(photoUrl: string) => handleAddImageUrl(photoUrl)} index={9999} />

                <Box sx={{ mt: 1 }}>
                  <Divider variant="middle" component="div" />
                  <Grid2 container spacing={2}>
                    {imageUrls.map((url, idx) => (
                      <Box key={url + idx}>
                        <Box component="img" src={url} alt="Preview" sx={{ height: 100, mt: 1 }} />
                        <IconButton onClick={() => handleDeleteImage(url)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                  </Grid2>
                </Box>
              </Grid2>

              {/* Itinerary */}
              <Grid2 size={{ xs: 12 }}>
                <Itinerary onChange={handleItinerary} itineraryItems={values.itinerary as DayItinerary[]} />
              </Grid2>

              {/* Extra Info */}
              <Grid2 size={{ xs: 12 }}>
                <ExtraInfoComponent
                  onChange={handleExtraInfo}
                  extraInfoItems={values.extraInformation as ExtraInfo[]}
                />
              </Grid2>

              {/* Included Services */}
              <Grid2 size={{ xs: 12 }}>
                <EditableAccordion
                  label="What's Included?"
                  items={values.includedServices}
                  onItemsChange={(items: any) => setFieldValue('includedServices', items)}
                />
                {touched.includedServices && errors.includedServices && (
                  <Typography variant="caption" color="error">
                    {errors.includedServices as string}
                  </Typography>
                )}
              </Grid2>

              {/* Excluded Services */}
              <Grid2 size={{ xs: 12 }}>
                <EditableAccordion
                  label="What is NOT Included?"
                  items={values.excludedServices}
                  onItemsChange={(items: any) => setFieldValue('excludedServices', items)}
                />
                {touched.excludedServices && errors.excludedServices && (
                  <Typography variant="caption" color="error">
                    {errors.excludedServices as string}
                  </Typography>
                )}
              </Grid2>

              {/* Cancellation Policy */}
              <Grid2 size={{ xs: 12 }}>
                <Box mt={4}>
                  <Typography variant="h6" gutterBottom>
                    Cancellation Policy
                  </Typography>

                  {/* Base Fee and Currency row */}
                  <Box display="flex" gap={2}>
                    <TextField
                      label="Base Non-Refundable Fee"
                      type="number"
                      name="cancellationPolicy.baseFee"
                      value={values.cancellationPolicy.baseFee}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ flex: 1 }}
                      InputProps={{ inputProps: { min: 0, step: '0.01' } }}
                      error={touched.cancellationPolicy?.baseFee && Boolean(errors.cancellationPolicy?.baseFee)}
                      helperText={touched.cancellationPolicy?.baseFee && errors.cancellationPolicy?.baseFee}
                    />

                    <TextField
                      label="Currency"
                      name="cancellationPolicy.currency"
                      value={values.cancellationPolicy.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ flex: 1 }}
                      error={touched.cancellationPolicy?.currency && Boolean(errors.cancellationPolicy?.currency)}
                      helperText={touched.cancellationPolicy?.currency && errors.cancellationPolicy?.currency}
                    />
                  </Box>

                  {/* Note field */}
                  <TextField
                    label="Policy Note or Disclaimers"
                    name="cancellationPolicy.note"
                    multiline
                    rows={3}
                    value={values.cancellationPolicy.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={touched.cancellationPolicy?.note && Boolean(errors.cancellationPolicy?.note)}
                    helperText={touched.cancellationPolicy?.note && errors.cancellationPolicy?.note}
                  />

                  {/* Rules section */}
                  <Box mt={3}>
                    <Typography variant="subtitle1">Refund Rules</Typography>
                    {values.cancellationPolicy.rules.map((rule, idx) => (
                      <Box key={idx} display="flex" alignItems="center" gap={2} sx={{ mb: 2, mt: 1 }}>
                        <TextField
                          type="number"
                          label="Days Before Start"
                          name={`cancellationPolicy.rules.${idx}.daysBeforeStart`}
                          value={rule.daysBeforeStart}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{ flex: 1 }}
                          error={
                            touched.cancellationPolicy?.rules?.[idx]?.daysBeforeStart &&
                            Boolean(errors.cancellationPolicy?.rules?.[idx]?.daysBeforeStart)
                          }
                          helperText={
                            touched.cancellationPolicy?.rules?.[idx]?.daysBeforeStart &&
                            errors.cancellationPolicy?.rules?.[idx]?.daysBeforeStart
                          }
                        />

                        <TextField
                          type="number"
                          label="Refund %"
                          name={`cancellationPolicy.rules.${idx}.refundPercentage`}
                          value={rule.refundPercentage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{ flex: 1 }}
                          error={
                            touched.cancellationPolicy?.rules?.[idx]?.refundPercentage &&
                            Boolean(errors.cancellationPolicy?.rules?.[idx]?.refundPercentage)
                          }
                          helperText={
                            touched.cancellationPolicy?.rules?.[idx]?.refundPercentage &&
                            errors.cancellationPolicy?.rules?.[idx]?.refundPercentage
                          }
                        />

                        <IconButton
                          color="error"
                          onClick={() => {
                            const updated = [...values.cancellationPolicy.rules];
                            updated.splice(idx, 1);
                            setFieldValue('cancellationPolicy.rules', updated);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}

                    <Button
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        const updated = [...values.cancellationPolicy.rules];
                        updated.push({ daysBeforeStart: 0, refundPercentage: 0 });
                        setFieldValue('cancellationPolicy.rules', updated);
                      }}
                    >
                      Add Rule
                    </Button>
                  </Box>
                </Box>
              </Grid2>
            </Grid2>

            {/* Submit Button */}
            <Box sx={{ mt: 3, textAlign: 'center' }}>
              <Button variant="contained" color="primary" type="submit" size="large" disabled={isSubmitting || loading}>
                {isSubmitting || loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : buttonString}
              </Button>
            </Box>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              action={action}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditTripForm;
