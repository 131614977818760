import React, { useMemo } from 'react';
import { useParams, Navigate, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Box,
  Avatar,
  Typography,
  CircularProgress,
  Alert,
  Chip,
  Card,
  CardContent,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import iso6391 from 'iso-639-1';
import countryList from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkIcon from '@mui/icons-material/Link';
import LanguageIcon from '@mui/icons-material/Language';

import { PUBLIC_USER_QUERY } from '../../graphql/users';

import GuideStatsSection from '../../components/User/Stats/GuideStatsSection';
import OrganizerStatsSection from '../../components/User/Stats/OrganizerStatsSection';

import { Role, PublicUserQuery, PublicUserQueryVariables } from '../../generated/graphql';

// Pre-build helper maps for countries & languages
const countryCodeToName: Record<string, string> = countryList.getData().reduce(
  (acc, c) => {
    acc[c.code] = c.name;
    return acc;
  },
  {} as Record<string, string>
);
const getCountryName = (code: string) => countryCodeToName[code] || code;
const getLanguageName = (code: string) => iso6391.getName(code) || code;

function getBadgeColor(level: string) {
  switch (level) {
    case 'LEVEL_1':
      return '#CD7F32'; // Bronze
    case 'LEVEL_2':
      return '#C0C0C0'; // Silver
    case 'LEVEL_3':
      return '#FFD700'; // Gold
    default:
      return '#808080'; // Gray
  }
}

function getSocialIcon(type: string) {
  switch (type.toLowerCase()) {
    case 'linkedin':
      return <LinkedInIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'facebook':
      return <FacebookIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'github':
      return <GitHubIcon />;
    default:
      return <LinkIcon />;
  }
}

const UserPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, loading, error } = useQuery<PublicUserQuery, PublicUserQueryVariables>(PUBLIC_USER_QUERY, {
    variables: { id: userId! },
    skip: !userId,
    fetchPolicy: 'network-only',
  });

  // Checking user roles
  const isGuide = useMemo(() => data?.user?.roles?.includes(Role.Guide), [data]);
  const isOrganizer = useMemo(() => data?.user?.roles?.includes(Role.Organizer), [data]);

  if (!userId) return <Navigate to="/" replace />;

  // Loading & error states
  if (loading) {
    return (
      <Box textAlign="center" mt={6}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 6 }}>
        <Alert severity="error">Error fetching user data: {error.message}</Alert>
      </Container>
    );
  }

  const user = data?.user;
  if (!user) {
    return (
      <Container maxWidth="sm" sx={{ mt: 6 }}>
        <Alert severity="info">No user data found.</Alert>
      </Container>
    );
  }

  const userLevelColor = getBadgeColor(user.level || '');
  const hasLevel = user.level && user.level !== 'LEVEL_0';

  return (
    <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
      {/* Header: Avatar + Name + Level */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          mb: 4,
          textAlign: isMobile ? 'center' : 'left',
        }}
      >
        <Avatar
          src={user.imageUrl || '/images/default_avatar.png'}
          alt={user.firstname || 'User'}
          sx={{
            width: isMobile ? 80 : 120,
            height: isMobile ? 80 : 120,
            mb: isMobile ? 2 : 0,
            mr: isMobile ? 0 : 4,
          }}
        />
        <Box>
          <Typography variant={isMobile ? 'h5' : 'h4'} fontWeight="bold" sx={{ lineHeight: 1.2 }}>
            {user.firstname || user.lastname ? `${user.firstname ?? ''} ${user.lastname ?? ''}` : user.email}
          </Typography>
          {user.position && (
            <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 0.5 }}>
              {user.position}
            </Typography>
          )}
          {hasLevel && (
            <Chip
              variant="outlined"
              label={`Level: ${user.level.replace('LEVEL_', '')}`}
              icon={<LoyaltyIcon sx={{ color: userLevelColor }} />}
              sx={{
                mt: 1,
                borderColor: userLevelColor,
                color: userLevelColor,
                fontWeight: 600,
              }}
            />
          )}
        </Box>
      </Box>

      {/* About me */}
      {user.description && (
        <Card elevation={1} sx={{ mb: 3, borderRadius: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              About Me
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
              {user.description}
            </Typography>
          </CardContent>
        </Card>
      )}

      {/* Public Info */}
      <Card elevation={1} sx={{ mb: 3, borderRadius: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 1 }}>
            Public Information
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {/* Languages */}
          {user.languagesSpoken?.length ? (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 1 }}>
                Languages
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {user.languagesSpoken.map((lang) => (
                  <Chip
                    key={lang}
                    icon={<LanguageIcon />}
                    label={getLanguageName(lang)}
                    variant="outlined"
                    sx={{ fontWeight: 500 }}
                  />
                ))}
              </Box>
            </Box>
          ) : null}

          {/* Countries Visited */}
          {user.countriesVisited?.length ? (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 1 }}>
                Countries Visited
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {user.countriesVisited.map((code) => (
                  <Chip
                    key={code}
                    variant="outlined"
                    label={getCountryName(code)}
                    icon={<ReactCountryFlag countryCode={code} svg style={{ width: '1em', height: '1em' }} />}
                    sx={{ fontWeight: 500 }}
                  />
                ))}
              </Box>
            </Box>
          ) : null}

          {/* Social Links */}
          {user.socialMediaLinks?.length ? (
            <Box>
              <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 1 }}>
                Social Links
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {user.socialMediaLinks.map((link) => (
                  <Button
                    key={link.type}
                    variant="outlined"
                    color="primary"
                    startIcon={getSocialIcon(link.type)}
                    href={link.url}
                    target="_blank"
                    sx={{ textTransform: 'none' }}
                  >
                    {link.type.charAt(0).toUpperCase() + link.type.slice(1)}
                  </Button>
                ))}
              </Box>
            </Box>
          ) : null}
        </CardContent>
      </Card>

      {/* Organizer Stats */}
      {isOrganizer && <OrganizerStatsSection organizerId={user.id} />}

      {/* Guide Stats */}
      {isGuide && <GuideStatsSection guideId={user.id} />}

      {/* CTA */}
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button variant="contained" component={RouterLink} to="/" color="primary" sx={{ textTransform: 'none' }}>
          Return to Home
        </Button>
      </Box>
    </Container>
  );
};

export default UserPage;
