import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  CircularProgress,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CreateTripParticipantMutation,
  CreateTripParticipantMutationVariables,
  Trip,
  User,
  UsersQuery,
  UsersQueryVariables,
} from '../../../generated/graphql';
import { USERS_QUERY } from '../../../graphql/users';
import { CREATE_TRIP_PARTICIPANT_MUTATION } from '../../../graphql/trip-participants';

interface UserSearchProps {
  tripId: string;
  excludedUserIds: string[];
  onParticipantAdded: () => void;
  trip: Trip;
}

const UserSearch: React.FC<UserSearchProps> = ({ tripId, excludedUserIds, onParticipantAdded, trip }) => {
  const [query, setQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [finalPrice, setFinalPrice] = useState<number>(trip.price || 0);

  const [searchUsers, { loading, data, error }] = useLazyQuery<UsersQuery, UsersQueryVariables>(USERS_QUERY, {
    variables: {
      filter: {
        query,
        excludeUserIds: excludedUserIds,
      },
      first: 10,
    },
    fetchPolicy: 'network-only',
  });

  const [createTripParticipant, { loading: creating }] = useMutation<
    CreateTripParticipantMutation,
    CreateTripParticipantMutationVariables
  >(CREATE_TRIP_PARTICIPANT_MUTATION);

  const searchUsersCallback = useCallback(() => {
    if (query.length >= 2) {
      searchUsers();
    }
  }, [query, searchUsers]);

  useEffect(() => {
    searchUsersCallback();
  }, [searchUsersCallback]);

  const handleAddParticipant = async () => {
    if (!selectedUser) return;

    try {
      await createTripParticipant({
        variables: {
          input: {
            tripId,
            userId: selectedUser.id,
            finalPrice,
          },
        },
      });
      setSelectedUser(null);
      setQuery('');
      setFinalPrice(0);
      onParticipantAdded();
    } catch (err) {
      console.error('Error creating trip participant:', err);
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Add Participant Manually
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Search for User by Name/Email"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        helperText="Type at least 2 characters"
      />
      {loading && <CircularProgress size={24} sx={{ mt: 2 }} />}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          Error fetching users: {error.message}
        </Typography>
      )}
      {data?.users?.edges?.length ? (
        <List>
          {data.users.edges.map((edge) => {
            const node = edge.node;
            return (
              <ListItem
                key={node.id}
                onClick={() => setSelectedUser(node as User)}
                sx={{
                  cursor: 'pointer',
                  bgcolor: selectedUser?.id === node.id ? 'action.selected' : 'inherit',
                }}
              >
                <ListItemAvatar>
                  <Avatar src={node.imageUrl || '/images/default_avatar.png'} />
                </ListItemAvatar>
                <ListItemText primary={`${node.firstname} ${node.lastname}`} secondary={node.email} />
              </ListItem>
            );
          })}
        </List>
      ) : null}

      {selectedUser && (
        <Box sx={{ mt: 2 }}>
          <Typography>
            Selected User: {selectedUser.firstname} {selectedUser.lastname}
          </Typography>
          <TextField
            label="Final Price"
            type="number"
            variant="outlined"
            value={finalPrice || ''}
            onChange={(e) => {
              const value = e.target.value;
              setFinalPrice(value ? parseFloat(value) : 0);
            }}
            sx={{ mt: 1, width: 150 }}
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={handleAddParticipant} disabled={creating}>
              {creating ? 'Adding...' : 'Add to Trip'}
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default UserSearch;
