import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Box,
  Alert,
  useTheme,
  useMediaQuery,
  Snackbar,
} from '@mui/material';
import { useMutation, ApolloError } from '@apollo/client';
import { Trip } from '../../../generated/graphql';
import { REQUEST_PARTICIPATION_MUTATION } from '../../../graphql/trip-participants';

interface RequestParticipationModalProps {
  open: boolean;
  onClose: () => void;
  trip: Trip;
}

const RequestParticipationModal: React.FC<RequestParticipationModalProps> = ({ open, onClose, trip }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [motivation, setMotivation] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const [requestParticipation] = useMutation(REQUEST_PARTICIPATION_MUTATION);

  const handleSubmit = async () => {
    setSubmitting(true);
    setError(null);
    try {
      await requestParticipation({
        variables: {
          input: {
            tripId: trip.id,
            motivation,
          },
        },
      });
      setIsSuccess(true);
      // Close automatically after a brief success display
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      if (err instanceof ApolloError) {
        setError(err.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      setShowError(true);
    } finally {
      setSubmitting(false);
    }
  };

  // 1) Extract the cancellation policy info
  const policy = trip.cancellationPolicy;
  const hasPolicy = !!policy;

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth PaperProps={{ sx: { borderRadius: 3 } }}>
        <DialogTitle sx={{ fontWeight: 'bold' }}>
          Request to Join <span style={{ color: theme.palette.primary.main }}>{trip.title}</span>
        </DialogTitle>

        <DialogContent dividers>
          {isSuccess ? (
            <Alert severity="success" sx={{ mb: 2 }}>
              Successfully requested! The organizer will review your request.
            </Alert>
          ) : (
            <>
              {/* 2) Display the cancellation policy first */}
              {hasPolicy ? (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ color: theme.palette.primary.dark }}>
                    Cancellation Policy
                  </Typography>

                  {/* Show a note if provided */}
                  {policy?.note && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {policy.note}
                    </Typography>
                  )}

                  {/* Possibly show base fee & currency */}
                  {policy?.baseFee != null && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Base Fee: {policy.baseFee} {policy.currency ? policy.currency : 'EUR'}
                    </Typography>
                  )}

                  {/* Show rules, if any */}
                  {policy?.rules && policy.rules.length > 0 && (
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2" fontWeight="bold">
                        Refund Rules:
                      </Typography>
                      {policy.rules.map((r) => (
                        <Typography key={r.id} variant="body2">
                          If you cancel {r.daysBeforeStart}+ days before start:
                          {` ${r.refundPercentage}% refund`}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              ) : (
                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                  No specific cancellation policy provided by the organizer.
                </Typography>
              )}

              {/* 3) Then prompt for motivation */}
              <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                You are requesting a spot on this trip. Please share any motivation or special requests below
                (optional).
              </Typography>

              <TextField
                label="Your Motivation (Optional)"
                placeholder="E.g. I'm excited to explore new places!"
                fullWidth
                multiline
                rows={isMobile ? 3 : 4}
                value={motivation}
                onChange={(e) => setMotivation(e.target.value)}
                sx={{ mt: 1 }}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} disabled={submitting} color="inherit">
            {isSuccess ? 'Close' : 'Cancel'}
          </Button>
          {!isSuccess && (
            <Box sx={{ position: 'relative' }}>
              <Button variant="contained" onClick={handleSubmit} disabled={submitting}>
                {submitting ? 'Requesting...' : 'Confirm'}
              </Button>
              {submitting && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: theme.palette.primary.light,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    mt: '-12px',
                    ml: '-12px',
                  }}
                />
              )}
            </Box>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestParticipationModal;
