import { SvgIcon } from '@mui/material';

interface PassportIconProps {
  size?: number;
}

function PassportIcon({ size = 16 }: PassportIconProps) {
  return (
    <SvgIcon>
      <svg
        width={size}
        height={size}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
          <path
            d="M2525 5102 c-107 -38 -188 -117 -222 -217 -8 -25 -54 -192 -102 -373
      l-88 -327 -939 -5 -939 -5 -35 -22 c-20 -12 -46 -40 -58 -62 l-22 -39 2 -1804
      3 -1803 24 -34 c13 -18 42 -42 65 -52 39 -18 88 -19 1064 -19 l1023 0 617
      -166 c339 -91 642 -168 673 -171 42 -4 76 0 129 16 62 19 81 31 135 84 46 45
      68 77 83 117 11 30 255 932 542 2005 515 1921 522 1951 517 2025 -2 45 -13 94
      -26 123 -29 67 -113 148 -181 175 -30 12 -512 144 -1070 293 -711 191 -1034
      274 -1080 276 -45 2 -81 -2 -115 -15z m1159 -432 c556 -149 1023 -275 1038
      -281 41 -17 96 -82 104 -125 4 -20 2 -58 -4 -83 -6 -25 -243 -912 -526 -1970
      -312 -1162 -524 -1937 -537 -1958 -27 -43 -85 -73 -142 -73 -39 0 -632 152
      -645 165 -3 3 4 5 15 5 37 0 142 61 179 103 46 54 69 99 83 165 7 36 11 273
      11 753 0 662 1 700 18 695 66 -20 71 -19 78 11 4 15 24 90 44 166 25 94 34
      140 26 143 -6 2 -42 13 -80 24 -54 15 -72 17 -78 7 -4 -6 -8 295 -8 671 0 500
      3 682 11 682 7 0 46 -10 89 -21 l77 -21 103 -339 104 -338 68 -17 c38 -9 70
      -15 72 -13 2 2 0 139 -3 304 -4 165 -6 303 -4 306 1 4 40 -4 85 -16 46 -13
      102 -28 125 -34 40 -11 45 -17 98 -110 53 -94 58 -99 101 -111 24 -7 46 -11
      48 -9 2 2 0 64 -5 137 l-9 133 70 104 c38 57 71 109 72 115 2 7 -17 18 -41 25
      l-43 14 -99 -59 -98 -58 -122 33 c-67 19 -123 36 -126 38 -2 3 66 119 152 260
      85 140 155 260 155 265 0 7 -110 42 -134 42 -3 0 -121 -108 -262 -240 -145
      -136 -263 -239 -271 -236 -7 3 -67 20 -133 37 -118 31 -122 34 -152 75 -33 46
      -95 95 -155 122 -33 15 -86 18 -389 22 l-350 5 86 320 c47 176 94 334 103 352
      27 50 82 83 141 83 34 0 381 -89 1060 -270z m-699 -682 c39 -21 71 -56 84 -91
      8 -20 11 -522 11 -1638 0 -1337 -2 -1615 -14 -1642 -17 -42 -66 -83 -114 -97
      -25 -6 -478 -10 -1349 -10 l-1313 0 0 1750 0 1750 1333 -2 c1217 -3 1335 -4
      1362 -20z"
          />
          <path
            d="M3395 2828 c-14 -46 -35 -128 -35 -141 0 -10 25 -21 80 -35 l80 -20
      11 42 c6 22 15 58 21 79 7 23 8 41 2 46 -8 7 -131 41 -149 41 -3 0 -7 -6 -10
      -12z"
          />
          <path
            d="M3725 2738 c-8 -24 -35 -136 -35 -145 0 -13 150 -47 159 -37 9 11 41
      124 41 145 0 11 -22 21 -72 32 -87 20 -87 20 -93 5z"
          />
          <path
            d="M4040 2588 c-12 -44 -18 -84 -13 -88 9 -9 153 -44 157 -39 8 12 42
      160 37 164 -4 2 -41 12 -83 23 l-76 20 -22 -80z"
          />
          <path
            d="M3730 2125 c-24 -91 -41 -167 -39 -168 11 -9 160 -43 163 -38 10 16
      87 324 82 328 -6 6 -136 43 -152 43 -6 0 -30 -74 -54 -165z"
          />
          <path
            d="M3437 1033 c-116 -433 -154 -589 -145 -594 18 -12 148 -42 154 -37 5
      5 314 1154 314 1168 0 4 -30 15 -67 24 -38 9 -75 19 -83 21 -13 4 -45 -106
      -173 -582z"
          />
          <path
            d="M1590 3649 c-470 -41 -852 -396 -935 -869 -70 -398 113 -819 453
      -1043 95 -62 140 -84 258 -123 260 -85 530 -66 779 56 278 135 473 374 558
      684 19 70 22 106 22 256 -1 182 -8 228 -56 360 -163 444 -603 721 -1079 679z
      m144 -207 c61 -97 143 -303 161 -404 l7 -38 -216 0 c-119 0 -216 2 -216 5 0 3
      9 39 21 81 36 132 80 238 146 352 33 56 63 57 97 4z m-315 -44 c-30 -59 -93
      -238 -114 -325 l-17 -73 -189 0 -188 0 21 38 c63 107 175 227 279 296 50 33
      202 106 222 106 4 0 -2 -19 -14 -42z m662 -18 c138 -68 286 -207 354 -332 l26
      -48 -189 0 -189 0 -13 63 c-15 70 -68 230 -105 313 -14 31 -25 58 -25 60 0 6
      78 -25 141 -56z m-826 -770 l0 -220 -206 0 -206 0 -12 70 c-20 112 -11 317 16
      363 2 4 95 7 206 7 l202 0 0 -220z m689 114 c5 -56 5 -154 1 -218 l-7 -116
      -248 0 -247 0 -7 31 c-8 41 -8 337 0 378 l7 31 246 -2 246 -3 9 -101z m597 49
      c17 -76 17 -253 0 -325 l-12 -58 -207 0 -207 0 0 220 0 220 207 0 207 0 12
      -57z m-1241 -605 c21 -91 60 -210 97 -296 19 -46 33 -85 30 -88 -10 -10 -145
      53 -219 102 -40 27 -101 76 -134 110 -62 62 -154 188 -154 211 0 10 43 13 184
      13 l184 0 12 -52z m595 15 c-14 -77 -77 -250 -127 -346 -50 -94 -53 -98 -82
      -95 -28 3 -36 12 -82 102 -45 86 -110 260 -130 349 l-6 27 217 0 217 0 -7 -37z
      m565 33 c0 -11 -47 -85 -86 -137 -61 -80 -155 -163 -242 -214 -72 -43 -181
      -90 -188 -82 -2 2 14 43 35 93 36 83 91 254 91 281 0 6 4 23 10 37 l10 26 185
      0 c102 0 185 -2 185 -4z"
          />
          <path
            d="M814 1027 c-3 -8 -4 -47 -2 -88 l3 -74 873 -3 872 -2 0 90 0 90 -870
      0 c-720 0 -872 -2 -876 -13z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default PassportIcon;
