import { gql } from '@apollo/client';

/**
 * Fragment for the trip expense data
 */
export const TRIP_EXPENSE_DATA_FRAGMENT = gql`
  fragment TripExpenseData on TripExpense {
    id
    title
    amount
    currency
    url
    description
    cancellationDeadline
    fileKey
    tripId
    createdAt
    updatedAt
    organizerId
  }
`;

export const TRIP_EXPENSES_QUERY = gql`
  query TripExpenses($tripId: String!) {
    tripExpenses(tripId: $tripId) {
      ...TripExpenseData
    }
  }
  ${TRIP_EXPENSE_DATA_FRAGMENT}
`;

export const TRIP_EXPENSE_FILE_URL_QUERY = gql`
  query TripExpenseFileUrl($expenseId: String!) {
    tripExpenseFileUrl(expenseId: $expenseId)
  }
`;

export const CREATE_TRIP_EXPENSE_MUTATION = gql`
  mutation CreateTripExpense($tripId: String!, $input: CreateTripExpenseDto!) {
    createTripExpense(tripId: $tripId, input: $input) {
      ...TripExpenseData
    }
  }
  ${TRIP_EXPENSE_DATA_FRAGMENT}
`;

export const UPDATE_TRIP_EXPENSE_MUTATION = gql`
  mutation UpdateTripExpense($expenseId: String!, $input: UpdateTripExpenseDto!) {
    updateTripExpense(expenseId: $expenseId, input: $input) {
      ...TripExpenseData
    }
  }
  ${TRIP_EXPENSE_DATA_FRAGMENT}
`;

export const DELETE_TRIP_EXPENSE_MUTATION = gql`
  mutation DeleteTripExpense($tripId: String!, $expenseId: String!) {
    deleteTripExpense(tripId: $tripId, expenseId: $expenseId)
  }
`;
