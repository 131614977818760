import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CircularProgress, Container, Alert, Typography, Box, Button } from '@mui/material';

import { ORGANIZER_TRIP_QUERY } from '../../graphql/trips';
import {
  ContinentEnum,
  CreateDayItineraryDto,
  CreateExtraInfoDto,
  MeQuery,
  MeQueryVariables,
  OrganizerTripQuery,
  OrganizerTripQueryVariables,
  Trip,
  TripStatusEnum,
  User,
} from '../../generated/graphql';
import { ME_QUERY } from '../../graphql/users';

import EditTripForm from '../../components/Trips/EditTripForm';
import { useTranslation } from 'react-i18next';

const TripEditPage: React.FC = () => {
  const { t } = useTranslation();
  const { tripId } = useParams<{ tripId: string }>();
  const navigate = useNavigate();

  // 1) Query for the trip data if editing
  const { data, loading, error } = useQuery<OrganizerTripQuery, OrganizerTripQueryVariables>(ORGANIZER_TRIP_QUERY, {
    variables: { id: tripId! },
    skip: !tripId,
  });

  // 2) We also fetch the current user for ownership
  const dataMe = useQuery<MeQuery, MeQueryVariables>(ME_QUERY);

  // 3) A blank trip object if we are creating a new trip
  const blankTrip = {
    title: '',
    description: '',
    temporaryBookingLink: '',
    startDate: undefined,
    endDate: undefined,
    numberOfPeople: 1,
    approvedParticipantsCount: 0,
    price: 0,
    country: '', // Start empty
    continent: ContinentEnum.Africa,
    images: [],
    includedServices: [],
    excludedServices: [],
    itinerary: [{ description: '', photoUrls: [] } as CreateDayItineraryDto],
    extraInformation: [{ title: '', description: '' } as CreateExtraInfoDto],
    createdAt: '',
    id: '',
    organizer: dataMe.data?.me as User,
    status: TripStatusEnum.Initiated,
    updatedAt: '',
  };

  // 4) Render states
  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">Error loading trip data.</Alert>
      </Container>
    );
  }

  const trip = data?.organizerTrip as Trip | undefined;

  // 5) If no trip ID => Create mode
  if (!trip && !tripId) {
    return (
      <Container sx={{ mt: 4, mb: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4">Create Trip</Typography>

          <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
            {t('back')}
          </Button>
        </Box>
        <EditTripForm trip={blankTrip} isCreatingTrip />
      </Container>
    );
  }
  // 6) If we have trip => Edit mode
  else if (trip) {
    return (
      <Container sx={{ mt: 4, mb: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4">Edit Trip</Typography>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
        <EditTripForm trip={trip} isCreatingTrip={false} />
      </Container>
    );
  } else {
    // 7) Fallback if trip not found
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">Trip not found.</Alert>
      </Container>
    );
  }
};

export default TripEditPage;
