import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Alert, Typography, styled } from '@mui/material';
import { GuideReviewStatsForUserQuery, GuideReviewStatsForUserQueryVariables } from '../../../generated/graphql';
import { GUIDE_REVIEW_STATS_FOR_USER_QUERY } from '../../../graphql/trip-reviews';

const ScrollRow = styled('div')(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '&::-webkit-scrollbar': {
    height: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    borderRadius: 4,
  },
}));

const StatsCard = styled('div')(({ theme }) => ({
  position: 'relative',
  minWidth: 280,
  height: 180,
  flexShrink: 0,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 3px 8px rgba(0,0,0,0.15)',
  overflow: 'hidden',
  backgroundColor: '#f5f5f5',
}));

const BackgroundImage = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'brightness(0.65)',
}));

const ContentOverlay = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  padding: theme.spacing(2),
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%)',
}));

interface GuideStatsSectionProps {
  guideId: string;
}

const GuideStatsSection: React.FC<GuideStatsSectionProps> = ({ guideId }) => {
  const { data, loading, error } = useQuery<GuideReviewStatsForUserQuery, GuideReviewStatsForUserQueryVariables>(
    GUIDE_REVIEW_STATS_FOR_USER_QUERY,
    {
      variables: { guideId, first: 10 },
      fetchPolicy: 'network-only',
    }
  );

  if (loading) {
    return (
      <Box textAlign="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">Error loading stats: {error.message}</Alert>;
  }

  const statsConn = data?.guideReviewStatsForUser;
  if (!statsConn || !statsConn.edges?.length) {
    return null;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
        Guide Review Stats
      </Typography>

      <ScrollRow>
        {statsConn.edges.map((edge) => {
          const node = edge.node;
          if (!node) return null;

          const firstImage = node.trip?.images?.[0] || '/images/trip-placeholder.jpg';
          const tripTitle = node.trip?.title || 'Untitled Trip';

          return (
            <StatsCard key={edge.cursor}>
              <BackgroundImage style={{ backgroundImage: `url('${firstImage}')` }} />
              <ContentOverlay>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {tripTitle}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Overall: {node.averageOverall?.toFixed(1) || 'N/A'}/5
                </Typography>
                {/* TODO: what fields?? */}
                {/* <Typography variant="body2">
                  Communication: {node.averageCommunication?.toFixed(1) || 'N/A'}/5
                </Typography> */}
                <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
                  {node.reviewCount} total reviews
                </Typography>
              </ContentOverlay>
            </StatsCard>
          );
        })}
      </ScrollRow>
    </Box>
  );
};

export default GuideStatsSection;
