import React, { Dispatch, SetStateAction } from 'react';
import { Box, Typography, Grid, Paper, TextField, Rating, useTheme, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { User } from '../../../generated/graphql';

// A single guide's review data
export interface GuideReviewData {
  guideId: string;
  overallRating: number; // required
  ratingCommunication: number;
  publicComment: string;
  privateComment: string;
  activitiesRating: number;
  organizationRating: number;
  timingAccuracy: number;
}

interface GuideLevelReviewStepProps {
  guides: User[];
  data: GuideReviewData[];
  setData: Dispatch<SetStateAction<GuideReviewData[]>>;
}

// Reusable rating style
const ratingStyles = {
  '& .MuiRating-iconFilled': {
    color: '#FF385C',
  },
  '& .MuiRating-iconEmpty': {
    color: '#ccc',
  },
};

const GuideLevelReviewStep: React.FC<GuideLevelReviewStepProps> = ({ guides, data, setData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // For each item in `data`, find the corresponding `guide` by ID
  return (
    <Paper
      sx={{
        p: isMobile ? 2 : 3,
        borderRadius: 3,
        boxShadow: '0 2px 8px rgba(0,0,0,0.06)',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Rate Your Guides
      </Typography>

      {data.map((guideReview, idx) => {
        const guide = guides.find((g) => g.id === guideReview.guideId);
        if (!guide) return null;

        return (
          <Box key={guideReview.guideId} sx={{ borderBottom: '1px solid #eee', mb: 2, pb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              {guide.firstname} {guide.lastname}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Overall rating (required)</Typography>
                <Rating
                  value={guideReview.overallRating}
                  onChange={(_, val) => {
                    const newVal = val ?? guideReview.overallRating;
                    setData((prev) => prev.map((item, i) => (i === idx ? { ...item, overallRating: newVal } : item)));
                  }}
                  icon={<StarIcon fontSize="inherit" />}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  sx={ratingStyles}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Communication (optional)</Typography>
                <Rating
                  value={guideReview.ratingCommunication}
                  onChange={(_, val) => {
                    const newVal = val ?? guideReview.ratingCommunication;
                    setData((prev) =>
                      prev.map((item, i) => (i === idx ? { ...item, ratingCommunication: newVal } : item))
                    );
                  }}
                  icon={<StarIcon fontSize="inherit" />}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  sx={ratingStyles}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Activities (optional)</Typography>
                <Rating
                  value={guideReview.activitiesRating}
                  onChange={(_, val) => {
                    const newVal = val ?? guideReview.activitiesRating;
                    setData((prev) =>
                      prev.map((item, i) => (i === idx ? { ...item, activitiesRating: newVal } : item))
                    );
                  }}
                  icon={<StarIcon fontSize="inherit" />}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  sx={ratingStyles}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Organization (optional)</Typography>
                <Rating
                  value={guideReview.organizationRating}
                  onChange={(_, val) => {
                    const newVal = val ?? guideReview.organizationRating;
                    setData((prev) =>
                      prev.map((item, i) => (i === idx ? { ...item, organizationRating: newVal } : item))
                    );
                  }}
                  icon={<StarIcon fontSize="inherit" />}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  sx={ratingStyles}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Timing Accuracy (optional)</Typography>
                <Rating
                  value={guideReview.timingAccuracy}
                  onChange={(_, val) => {
                    const newVal = val ?? guideReview.timingAccuracy;
                    setData((prev) => prev.map((item, i) => (i === idx ? { ...item, timingAccuracy: newVal } : item)));
                  }}
                  icon={<StarIcon fontSize="inherit" />}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  sx={ratingStyles}
                />
              </Grid>

              {/* Comments */}
              <Grid item xs={12}>
                <Typography variant="body2">Public Comment (optional)</Typography>
                <TextField
                  multiline
                  rows={isMobile ? 2 : 3}
                  fullWidth
                  value={guideReview.publicComment}
                  onChange={(e) => {
                    const newVal = e.target.value;
                    setData((prev) => prev.map((item, i) => (i === idx ? { ...item, publicComment: newVal } : item)));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Private Comment (only staff sees)</Typography>
                <TextField
                  multiline
                  rows={isMobile ? 2 : 3}
                  fullWidth
                  value={guideReview.privateComment}
                  onChange={(e) => {
                    const newVal = e.target.value;
                    setData((prev) => prev.map((item, i) => (i === idx ? { ...item, privateComment: newVal } : item)));
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Paper>
  );
};

export default GuideLevelReviewStep;
