import { gql } from '@apollo/client';

export const CREATE_TRIP_REVIEW_MUTATION = gql`
  mutation CreateTripReview($input: CreateTripReviewInput!) {
    createTripReview(input: $input) {
      id
      createdAt
      overallRating
      publicComment
    }
  }
`;

export const CREATE_GUIDE_REVIEW_MUTATION = gql`
  mutation CreateGuideReview($input: CreateGuideReviewInput!) {
    createGuideReview(input: $input) {
      id
      createdAt
      overallRating
      publicComment
    }
  }
`;
