import { Box, Button, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Box my={2}>
      <Button
        onClick={() => navigate(-1)}
        sx={{ color: 'black', '&:hover': { opacity: 0.6, backgroundColor: 'transparent' }, padding: 0 }}
      >
        <ArrowBackIosNewIcon sx={{ mr: '8px', fontSize: '24px' }} />
        <Typography fontSize="large">{t('back')}</Typography>
      </Button>
    </Box>
  );
};

export default BackButton;
