import { Typography } from '@mui/material';
import { Box } from '@mui/system';

interface IconTextProps {
  icon: React.ReactNode;
  text: string;
}

const IconText = ({ icon, text }: IconTextProps) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box
        sx={{
          backgroundColor: 'secondary.main',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
      </Box>
      <Typography fontWeight={500}>{text}</Typography>
    </Box>
  );
};

export default IconText;
