import { Box } from '@mui/material';
import ServiceList from './Service';
import { useTranslation } from 'react-i18next';

interface IncludedAndExcludedServicesProps {
  includedServices: string[];
  excludedServices: string[];
}

const IncludedAndExcludedServices = ({ includedServices, excludedServices }: IncludedAndExcludedServicesProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="start" gap={3} my={5}>
      <ServiceList title={t('included_services')} services={includedServices} emoji="included" />
      <ServiceList title={t('excluded_services')} services={excludedServices} emoji="excluded" />
    </Box>
  );
};

export default IncludedAndExcludedServices;
