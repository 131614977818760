import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Avatar,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Divider,
  useTheme,
} from '@mui/material';
import {
  ParticipantStatusEnum,
  PaymentStatusEnum,
  Trip,
  TripParticipant,
  TripParticipantPayment,
  LoyaltyLevel,
} from '../../../generated/graphql';

interface ParticipantDetailModalProps {
  open: boolean;
  onClose: () => void;
  participant: TripParticipant;
  trip: Trip;
  userIsAdmin: boolean;

  // Callbacks
  onAccept: (userId: string, finalPrice?: number) => void;
  onReject: (userId: string) => void;
  onCancel: (userId: string) => void;
  onDelete: (participantId: string) => void;
  onAddPayment?: (userId: string, amount: number, status: PaymentStatusEnum) => Promise<void>;
  onUpdatePaymentStatus: (paymentId: string, newStatus: PaymentStatusEnum) => Promise<void>;
}

const getLoyaltyDiscount = (level: LoyaltyLevel): number => {
  switch (level) {
    case LoyaltyLevel.Level_1:
      return 0.05;
    case LoyaltyLevel.Level_2:
      return 0.08;
    case LoyaltyLevel.Level_3:
      return 0.1;
    case LoyaltyLevel.Level_4:
      return 0.15;
    default:
      return 0;
  }
};

const ParticipantDetailModal: React.FC<ParticipantDetailModalProps> = ({
  open,
  onClose,
  participant,
  trip,
  userIsAdmin,
  onAccept,
  onReject,
  onCancel,
  onDelete,
  onUpdatePaymentStatus,
  onAddPayment,
}) => {
  const theme = useTheme();

  console.log('participant', participant);
  // Basic user data
  const displayName = (participant.user?.firstname ?? '') + ' ' + (participant.user?.lastname ?? '');
  const phone = participant.user?.phone ?? '';
  const email = participant.user?.email ?? '';
  const address = participant.user?.contactAddress ?? '';
  const status = participant.status;

  // Local copies of finalPrice, payments, etc., so we can see changes instantly
  const [localFinalPrice, setLocalFinalPrice] = useState<number>(
    participant.finalPrice ??
      Math.floor(trip.price * (1 - getLoyaltyDiscount(participant.user?.level as LoyaltyLevel))) ??
      0
  );

  // Clone the array of payments so we can store local updates
  const [localPayments, setLocalPayments] = useState<TripParticipantPayment[]>(participant.payments || []);

  // Payment creation fields
  const [newPaymentAmount, setNewPaymentAmount] = useState<string>('');
  const [newPaymentStatus, setNewPaymentStatus] = useState<PaymentStatusEnum>(PaymentStatusEnum.Paid);

  // Re-init local data whenever `participant` changes
  useEffect(() => {
    setLocalFinalPrice(
      participant.finalPrice ??
        Math.floor(trip.price * (1 - getLoyaltyDiscount(participant.user?.level as LoyaltyLevel))) ??
        0
    );
    setLocalPayments(participant.payments || []);
  }, [participant, trip]);

  // Summation of *PAID* amounts
  const totalPaid =
    (participant.payments || []).reduce(
      (acc, pay) => (pay.status === PaymentStatusEnum.Paid ? acc + pay.amount : acc),
      0
    ) || 0;

  // Add this where other calculations are done
  const loyaltyDiscount = getLoyaltyDiscount(participant.user?.level as LoyaltyLevel);
  const discountedPrice = Math.floor(trip.price * (1 - loyaltyDiscount));

  // Accept => call parent, then close
  const handleAcceptClick = async () => {
    await onAccept(participant.userId, localFinalPrice);
    onClose();
  };

  // Reject => call parent, then close
  const handleRejectClick = async () => {
    await onReject(participant.userId);
    onClose();
  };

  // Cancel => call parent, then close
  const handleCancelClick = async () => {
    const totalPaidAmount = localPayments.reduce(
      (acc, pay) => (pay.status === PaymentStatusEnum.Paid ? acc + pay.amount : acc),
      0
    );

    if (
      !window.confirm(
        `This participant has paid €${totalPaidAmount}. Please ensure you have processed any necessary refunds before confirming the cancellation. Continue?`
      )
    ) {
      return;
    }

    await onCancel(participant.userId);
    onClose();
  };

  // Add Payment => update local state to reflect immediate changes
  const handleAddPayment = async () => {
    if (!newPaymentAmount || !onAddPayment) return;
    const parsed = parseFloat(newPaymentAmount);
    try {
      await onAddPayment(participant.userId, parsed, newPaymentStatus);
      // update local array
      setLocalPayments((prev) => [
        ...prev,
        {
          id: 'temp-' + Math.random().toString(36).substr(2, 5),
          amount: parsed,
          status: newPaymentStatus,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          tripParticipantId: participant.id,
        } as TripParticipantPayment,
      ]);
      setNewPaymentAmount('');
      setNewPaymentStatus(PaymentStatusEnum.Pending);
    } catch (err) {
      console.error('Error adding payment:', err);
    }
  };

  // Update Payment Status => reflect changes in local state
  const handlePaymentStatusChange = async (paymentId: string, newVal: PaymentStatusEnum) => {
    if (!onUpdatePaymentStatus) return;
    try {
      await onUpdatePaymentStatus(paymentId, newVal);
      setLocalPayments((prev) => prev.map((p) => (p.id === paymentId ? { ...p, status: newVal } : p)));
    } catch (err) {
      console.error(err);
    }
  };

  // If logged in user is Admin + participant is CANCELLED => allow permanent delete
  const handleDeleteClick = async () => {
    // calls the parent's onDelete
    await onDelete(participant.userId);
    onClose();
  };

  // Add this helper function
  const getStatusDisplay = (status: ParticipantStatusEnum) => {
    switch (status) {
      case ParticipantStatusEnum.CancelledByParticipant:
        return 'Cancellation Requested';
      default:
        return status;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ sx: { borderRadius: 3 } }}>
      <DialogTitle sx={{ pb: 1, fontWeight: 'bold', color: theme.palette.primary.dark }}>
        Manage Participant
      </DialogTitle>

      <DialogContent dividers sx={{ pt: 2 }}>
        {/* Basic user info */}
        <Box display="flex" alignItems="center" gap={2} mb={3}>
          <Avatar
            src={participant.user?.imageUrl || '/images/default_avatar.png'}
            alt={displayName}
            sx={{ width: 64, height: 64, border: `2px solid ${theme.palette.primary.light}` }}
          />
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {displayName}
            </Typography>
            <Typography variant="body2">Phone: {phone}</Typography>
            <Typography variant="body2">Email: {email}</Typography>
            {address && <Typography variant="body2">Address: {address}</Typography>}

            <Typography variant="body2" sx={{ mt: 1, fontWeight: 'bold', color: 'text.secondary' }}>
              Status: {getStatusDisplay(status)}
            </Typography>
          </Box>
        </Box>

        {/* If status is PENDING => show finalPrice edit + Accept/Reject */}
        {status === ParticipantStatusEnum.Pending && (
          <Box>
            {participant.motivation && (
              <Box mb={2}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                  Motivation
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                  {participant.motivation}
                </Typography>
              </Box>
            )}
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2, mb: 1.5 }}>
              Price Details
            </Typography>
            <Box
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: 1,
                p: 2,
                border: `1px solid ${theme.palette.grey[200]}`,
              }}
            >
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body2" color="text.secondary">
                  Base Trip Price:
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                  €{trip.price}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body2" color="text.secondary">
                  Loyalty Level:
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                  {participant.user?.level?.replace('_', ' ') || 'LEVEL 0'}
                  {loyaltyDiscount > 0 && (
                    <Typography component="span" color="success.main" sx={{ ml: 1 }}>
                      (-{(loyaltyDiscount * 100).toFixed(0)}%)
                    </Typography>
                  )}
                </Typography>
              </Box>

              <Divider sx={{ my: 1.5 }} />

              <Box display="flex" justifyContent="space-between">
                <Typography variant="subtitle2" fontWeight="bold">
                  Suggested Price:
                </Typography>
                <Typography variant="subtitle2" fontWeight="bold" color="primary.main">
                  €{discountedPrice.toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
              Set Final Price
            </Typography>
            <TextField
              type="number"
              size="small"
              value={localFinalPrice}
              onChange={(e) => setLocalFinalPrice(parseFloat(e.target.value))}
              sx={{ width: 130 }}
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Once you Accept this participant, the final price is locked in.
            </Typography>

            <Box display="flex" gap={2} mt={3}>
              <Button variant="contained" onClick={handleAcceptClick}>
                Accept
              </Button>
              <Button variant="outlined" color="warning" onClick={handleRejectClick}>
                Reject
              </Button>
            </Box>
          </Box>
        )}

        {/* If status is APPROVED => show Payment History etc. */}
        {status === ParticipantStatusEnum.Approved && (
          <>
            <Box mb={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Final Price: €{localFinalPrice}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Paid so far: €{totalPaid}
              </Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
              Payment History
            </Typography>
            {localPayments.length ? (
              <Table size="small" sx={{ mb: 2 }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>When</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {localPayments.map((pay) => (
                    <TableRow key={pay.id}>
                      <TableCell>€{pay.amount}</TableCell>
                      <TableCell>
                        <FormControl variant="standard" size="small">
                          <Select
                            value={pay.status}
                            onChange={(e) => handlePaymentStatusChange(pay.id, e.target.value as PaymentStatusEnum)}
                            sx={{ minWidth: 90 }}
                          >
                            {Object.values(PaymentStatusEnum).map((st) => (
                              <MenuItem key={st} value={st}>
                                {st}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {new Date(pay.createdAt).toLocaleDateString()} &nbsp;
                        {new Date(pay.createdAt).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="body2" sx={{ mb: 2 }}>
                No payments yet.
              </Typography>
            )}

            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                mb: 2,
                p: 2,
                backgroundColor: theme.palette.grey[100],
                borderRadius: 2,
              }}
            >
              <TextField
                label="Payment Amount"
                type="number"
                size="small"
                value={newPaymentAmount}
                onChange={(e) => setNewPaymentAmount(e.target.value)}
                sx={{ width: 130 }}
              />
              <FormControl size="small" sx={{ width: 130 }}>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  value={newPaymentStatus}
                  label="Status"
                  onChange={(e) => setNewPaymentStatus(e.target.value as PaymentStatusEnum)}
                >
                  {Object.values(PaymentStatusEnum).map((st) => (
                    <MenuItem key={st} value={st}>
                      {st}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button variant="contained" onClick={handleAddPayment} sx={{ height: 40 }}>
                Add Payment
              </Button>
            </Box>

            <Button variant="outlined" color="error" sx={{ mt: 2 }} onClick={handleCancelClick}>
              Cancel This Participant
            </Button>
          </>
        )}

        {/* If status is CANCELLED and user is admin => show "Delete" button */}
        {(status === ParticipantStatusEnum.Cancelled || status === ParticipantStatusEnum.Rejected) && userIsAdmin && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                This participant is cancelled. You can permanently delete them (Admin only).
              </Typography>
              <Button variant="contained" color="error" onClick={handleDeleteClick}>
                Permanently Delete
              </Button>
            </Box>
          </>
        )}

        {/* Add special handling for CANCELLED_BY_PARTICIPANT status */}
        {status === ParticipantStatusEnum.CancelledByParticipant && (
          <Box sx={{ mt: 2, p: 2, bgcolor: 'warning.light', borderRadius: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'warning.dark' }}>
              Cancellation Requested by Participant
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
              This participant has requested cancellation. Please: 1. Review any necessary refunds 2. Process refunds if
              needed 3. Confirm the cancellation using the button below
            </Typography>
            <Button variant="contained" color="warning" onClick={handleCancelClick}>
              Confirm Cancellation
            </Button>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParticipantDetailModal;
