import { SvgIcon } from '@mui/material';
import React from 'react';

interface TimezoneIconProps {
  size?: number;
}

function TimezoneIcon({ size = 34 }: TimezoneIconProps) {
  return (
    <SvgIcon>
      <svg
        width={size}
        height={size}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
          <path
            d="M1490 4309 c-499 -62 -933 -328 -1210 -742 -471 -705 -332 -1666 320
-2211 419 -351 968 -476 1495 -341 72 18 169 50 217 70 l88 37 -71 55 -71 54
-112 -35 c-178 -57 -267 -70 -466 -70 -188 0 -280 12 -438 60 -473 142 -857
519 -1005 985 -58 181 -71 270 -71 469 0 235 31 391 120 605 l36 86 27 -17
c49 -31 51 -39 51 -194 l0 -146 27 -26 c16 -14 63 -48 105 -76 98 -66 108 -81
108 -158 0 -57 -3 -65 -40 -112 l-40 -50 0 -104 c0 -96 2 -106 22 -125 13 -12
93 -49 178 -83 85 -34 165 -71 177 -83 22 -19 23 -28 23 -169 l0 -148 40 -80
c34 -68 40 -89 40 -143 0 -86 12 -100 116 -134 117 -38 127 -32 173 101 32 92
39 104 113 182 l78 83 0 77 0 76 84 82 c46 45 100 101 120 125 35 42 36 44 36
132 0 82 -2 92 -25 114 -23 24 -31 25 -135 25 -104 0 -112 1 -135 25 -18 17
-25 35 -25 63 0 57 -23 78 -120 112 -97 35 -120 56 -120 112 0 28 -7 46 -25
63 -22 23 -32 25 -117 25 l-93 0 -83 83 -82 82 0 102 c0 81 3 105 18 122 10
12 59 35 119 55 91 31 115 44 245 141 78 59 151 116 161 127 11 13 17 37 17
70 0 60 -31 98 -80 98 -19 0 -40 9 -55 25 -23 22 -25 32 -25 112 0 79 -3 93
-26 126 -14 21 -24 40 -22 42 9 8 183 56 263 72 65 12 138 17 265 17 199 0
288 -13 466 -70 l112 -35 71 54 71 55 -83 35 c-102 43 -235 81 -352 102 -108
20 -371 27 -475 14z"
          />
          <path
            d="M3290 4314 c-273 -33 -508 -109 -705 -228 -431 -261 -712 -669 -801
-1161 -25 -139 -25 -431 0 -570 89 -490 372 -901 801 -1160 449 -271 1034
-308 1523 -97 516 224 886 695 988 1257 25 139 25 431 0 570 -63 349 -217 644
-466 896 -250 254 -554 412 -905 474 -87 16 -363 28 -435 19z m407 -339 c544
-106 970 -534 1080 -1083 24 -120 24 -384 0 -504 -110 -551 -533 -975 -1085
-1085 -129 -26 -395 -24 -522 4 -550 121 -958 535 -1067 1081 -12 61 -17 136
-17 252 0 116 5 191 17 252 116 580 578 1016 1163 1097 95 13 332 6 431 -14z"
          />
          <path
            d="M3385 3815 c-22 -21 -25 -33 -25 -95 0 -62 3 -74 25 -95 15 -16 36
-25 55 -25 19 0 40 9 55 25 22 21 25 33 25 95 0 62 -3 74 -25 95 -15 16 -36
25 -55 25 -19 0 -40 -9 -55 -25z"
          />
          <path
            d="M2595 3485 c-41 -40 -34 -79 24 -136 40 -41 54 -49 85 -49 23 0 43 7
56 20 13 13 20 33 20 56 0 31 -8 45 -49 85 -57 58 -96 65 -136 24z"
          />
          <path
            d="M4149 3461 c-41 -40 -49 -54 -49 -85 0 -23 7 -43 20 -56 13 -13 33
-20 56 -20 31 0 45 8 85 49 58 57 65 96 24 136 -40 41 -79 34 -136 -24z"
          />
          <path
            d="M3385 3415 l-25 -24 0 -358 0 -357 -120 -121 c-103 -105 -120 -126
-120 -154 0 -43 38 -81 82 -81 28 0 50 18 175 143 l143 143 0 392 0 393 -25
24 c-15 16 -36 25 -55 25 -19 0 -40 -9 -55 -25z"
          />
          <path
            d="M2265 2695 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 21 -22 33
-25 95 -25 62 0 74 3 95 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -21 22 -33
25 -95 25 -62 0 -74 -3 -95 -25z"
          />
          <path
            d="M4425 2695 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 21 -22 33
-25 95 -25 62 0 74 3 95 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -21 22 -33
25 -95 25 -62 0 -74 -3 -95 -25z"
          />
          <path
            d="M2619 1931 c-58 -57 -65 -96 -24 -136 40 -41 79 -34 136 24 41 40 49
54 49 85 0 48 -28 76 -76 76 -31 0 -45 -8 -85 -49z"
          />
          <path
            d="M4120 1960 c-13 -13 -20 -33 -20 -56 0 -31 8 -45 49 -85 57 -58 96
-65 136 -24 41 40 34 79 -24 136 -40 41 -54 49 -85 49 -23 0 -43 -7 -56 -20z"
          />
          <path
            d="M3385 1655 c-22 -21 -25 -33 -25 -95 0 -62 3 -74 25 -95 15 -16 36
-25 55 -25 19 0 40 9 55 25 22 21 25 33 25 95 0 62 -3 74 -25 95 -15 16 -36
25 -55 25 -19 0 -40 -9 -55 -25z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default TimezoneIcon;
