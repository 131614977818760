import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { MY_TRIP_PARTICIPATIONS_QUERY, CANCEL_MY_PARTICIPATION_MUTATION } from '../../../../graphql/trip-participants';
import {
  MyTripParticipationsQuery,
  MyTripParticipationsQueryVariables,
  CancelMyParticipationMutation,
  CancelMyParticipationMutationVariables,
  ParticipantStatusEnum,
  TripParticipant,
} from '../../../../generated/graphql';
import {
  Box,
  CircularProgress,
  Alert,
  Typography,
  Grid,
  Button,
  Divider,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import RateReviewIcon from '@mui/icons-material/RateReview';

const MyTripsSection: React.FC = () => {
  const { data, loading, error, refetch } = useQuery<MyTripParticipationsQuery, MyTripParticipationsQueryVariables>(
    MY_TRIP_PARTICIPATIONS_QUERY,
    { fetchPolicy: 'network-only' }
  );

  const navigate = useNavigate();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelTarget, setCancelTarget] = useState<TripParticipant | null>(null);

  const [cancelMyParticipation, { loading: cancelLoading }] = useMutation<
    CancelMyParticipationMutation,
    CancelMyParticipationMutationVariables
  >(CANCEL_MY_PARTICIPATION_MUTATION, {
    onCompleted: () => {
      setCancelModalOpen(false);
      setCancelReason('');
      setCancelTarget(null);
      refetch();
    },
    onError: (err) => {
      console.error('Cancel participation error', err);
    },
  });

  if (loading) {
    return (
      <Box textAlign="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">Error loading your trip participations.</Alert>;
  }
  const participations = data?.myTripParticipations || [];
  if (!participations.length) {
    return (
      <Alert severity="info" sx={{ mt: 2 }}>
        You have no trip participations yet.
      </Alert>
    );
  }

  // Filter logic
  const pendingTrips = participations.filter((p) => p.status === ParticipantStatusEnum.Pending && p.trip);
  const cancelledTrips = participations.filter(
    (p) => p.status === ParticipantStatusEnum.Cancelled || p.status === ParticipantStatusEnum.CancelledByParticipant
  );
  const approved = participations.filter((p) => p.status === ParticipantStatusEnum.Approved);
  const now = DateTime.now();
  const upcomingTrips = approved.filter((p) => DateTime.fromISO(p.trip!.endDate) > now);
  const pastTrips = approved.filter((p) => DateTime.fromISO(p.trip!.endDate) <= now);

  const handleOpenCancelModal = (participant: TripParticipant) => {
    setCancelTarget(participant);
    setCancelModalOpen(true);
  };
  const handleCloseCancelModal = () => {
    setCancelModalOpen(false);
    setCancelReason('');
    setCancelTarget(null);
  };
  const handleConfirmCancel = () => {
    if (!cancelTarget?.trip?.id) return;
    cancelMyParticipation({
      variables: {
        input: {
          tripId: cancelTarget.trip.id,
          reason: cancelReason,
        },
      },
    });
  };

  const renderTripItem = (participant: TripParticipant, subheading?: string) => {
    const trip = participant.trip;
    if (!trip) return null;
    const placeholderImg = '/images/trip-placeholder.jpg';
    const tripImg = trip.images?.[0] || placeholderImg;

    const startDate = DateTime.fromISO(trip.startDate);
    const endDate = DateTime.fromISO(trip.endDate).toLocaleString(DateTime.DATE_MED);
    const dateRange = `${startDate.toLocaleString(DateTime.DATE_MED)} - ${endDate}`;

    // If user can cancel
    const now = DateTime.now();
    const canCancel =
      [ParticipantStatusEnum.Pending, ParticipantStatusEnum.Approved].includes(participant.status) && startDate > now; // trip not started

    // If user can review
    const endedAlready = DateTime.fromISO(trip.endDate) < now;
    const withinReviewPeriod = now.diff(DateTime.fromISO(trip.endDate), 'days').days <= 30;
    const canReview =
      endedAlready &&
      withinReviewPeriod &&
      participant.status === ParticipantStatusEnum.Approved &&
      !participant.hasTripReview;

    return (
      <Grid item xs={12} key={participant.id}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 2,
            overflow: 'hidden',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            position: 'relative',
          }}
        >
          {participant.hasTripReview && (
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'rgba(255,255,255,0.8)',
                borderRadius: 2,
                px: 1,
                py: 0.5,
              }}
            >
              <RateReviewIcon color="success" sx={{ fontSize: 20, mr: 0.5 }} />
              <Typography variant="caption" color="success.main" sx={{ fontWeight: 'bold' }}>
                Reviewed
              </Typography>
            </Box>
          )}
          {/* Left thumbnail */}
          <Box sx={{ width: 160, height: 120, bgcolor: '#f5f5f5' }}>
            <img src={tripImg} alt={trip.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Box>
          {/* Info */}
          <Box sx={{ flex: 1, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {trip.title}
              </Typography>
              {subheading && (
                <Typography variant="body2" color="primary">
                  {subheading}
                </Typography>
              )}
              <Typography variant="body2" color="text.secondary">
                {dateRange}
              </Typography>
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                Status: <strong>{participant.status}</strong>
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right', mt: 1 }}>
              {canCancel && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={() => handleOpenCancelModal(participant)}
                >
                  {participant.status === ParticipantStatusEnum.Pending ? 'Cancel Request' : 'Request Cancellation'}
                </Button>
              )}
              {canReview && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={() => navigate(`/trip/${trip.id}/review`)}
                >
                  Review This Trip
                </Button>
              )}
              <Button variant="contained" size="small" onClick={() => navigate(`/trip/${trip.id}`)}>
                View Trip
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const isEmpty = !pendingTrips.length && !cancelledTrips.length && !upcomingTrips.length && !pastTrips.length;

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        My Trips
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Manage and explore all your upcoming, past, or pending trips below.
      </Typography>
      <Divider sx={{ mb: 3 }} />

      {pendingTrips.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Pending Trips
          </Typography>
          <Grid container spacing={2}>
            {pendingTrips.map((p) => renderTripItem(p as TripParticipant, 'Awaiting approval...'))}
          </Grid>
        </Box>
      )}
      {upcomingTrips.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Upcoming Trips
          </Typography>
          <Grid container spacing={2}>
            {upcomingTrips.map((p) => renderTripItem(p as TripParticipant))}
          </Grid>
        </Box>
      )}
      {cancelledTrips.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Cancelled Trips
          </Typography>
          <Grid container spacing={2}>
            {cancelledTrips.map((p) => renderTripItem(p as TripParticipant, 'This trip was cancelled'))}
          </Grid>
        </Box>
      )}
      {pastTrips.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Past Trips
          </Typography>
          <Grid container spacing={2}>
            {pastTrips.map((p) => renderTripItem(p as TripParticipant))}
          </Grid>
        </Box>
      )}
      {isEmpty && (
        <Alert severity="info" sx={{ mt: 2 }}>
          You have no trip participations yet.
        </Alert>
      )}

      {/* Cancel Modal */}
      <Dialog open={cancelModalOpen} onClose={handleCloseCancelModal} fullWidth maxWidth="sm">
        <DialogTitle>Cancel Participation</DialogTitle>
        <DialogContent>
          <Typography paragraph>Are you sure you want to cancel this trip participation?</Typography>
          <TextField
            label="Reason (optional)"
            fullWidth
            multiline
            minRows={2}
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelModal} disabled={cancelLoading}>
            Keep My Spot
          </Button>
          <Button variant="contained" color="error" onClick={handleConfirmCancel} disabled={cancelLoading}>
            {cancelLoading ? 'Cancelling...' : 'Confirm Cancellation'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyTripsSection;
