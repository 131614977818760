import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, Grid, Typography, Box } from '@mui/material';
import TripCard from './TripCard';
import { PUBLIC_TRIPS_QUERY } from '../../graphql/trips';
import { t } from 'i18next';
import { CustomButton } from '../common/Button';
import { OrderDirection, PublicTripsQuery, PublicTripsQueryVariables, TripOrder } from '../../generated/graphql';
import { TripOrderField } from '../../generated/graphql';

const ITEMS_PER_PAGE = 4;

const TripsListContainer: React.FC = () => {
  const today = useMemo(() => new Date(), []);

  const { data, loading, error } = useQuery<PublicTripsQuery, PublicTripsQueryVariables>(PUBLIC_TRIPS_QUERY, {
    variables: {
      filter: {
        startDateFrom: today,
      },
      orderBy: {
        field: TripOrderField.StartDate,
        direction: OrderDirection.Asc,
      } as TripOrder,
    },
  });
  const [visibleTrips, setVisibleTrips] = React.useState(ITEMS_PER_PAGE);

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error || !data?.publicTrips.edges) {
    return (
      <Typography variant="subtitle1" color="error" sx={{ textAlign: 'center', mt: 4 }}>
        Unable to load trips. Please try again later.
      </Typography>
    );
  }

  const trips = data.publicTrips.edges.map((edge: any) => edge.node);

  if (trips.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6" color="textSecondary">
          Unfortunately, there are no upcoming trips at the moment.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Please check back later or contact us for more information.
        </Typography>
      </Box>
    );
  }

  const displayedTrips = trips.slice(0, visibleTrips);

  const loadMoreTrips = () => {
    setVisibleTrips((prev) => prev + ITEMS_PER_PAGE);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {displayedTrips.map((trip: any) => (
          <Grid item key={trip.id} xs={12} sm={6} md={3}>
            <TripCard trip={trip} />
          </Grid>
        ))}
      </Grid>
      {visibleTrips < trips.length && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CustomButton
            text={t('load_more')}
            variant="contained"
            onClick={loadMoreTrips}
            color="primary"
            sx={{
              color: 'black !important',
              mt: 4,
              mb: 6,
              px: 4,
              py: 1.5,
            }}
          ></CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default TripsListContainer;
