import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const WizardConfirmationStep: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        p: 2,
        textAlign: 'center',
        borderRadius: 3,
        backgroundColor: '#f9f9f9',
        mt: isMobile ? 2 : 4,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Ready to Submit?
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        You can review your inputs in the previous steps. Once you confirm, your reviews will be posted.
      </Typography>
    </Box>
  );
};

export default WizardConfirmationStep;
