import { SvgIcon } from '@mui/material';
import React from 'react';

interface SurfIconProps {
  size: number;
}

function SurfIcon({ size }: SurfIconProps) {
  return (
    <SvgIcon sx={{ width: size, height: size }}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
          <path
            d="M4375 5114 c-251 -27 -436 -65 -640 -131 -443 -143 -856 -371 -1276
-703 -417 -330 -922 -869 -1330 -1420 -439 -592 -816 -1279 -1025 -1866 -35
-100 -104 -324 -104 -340 0 -1 110 -39 244 -84 l245 -81 81 -245 c45 -134 83
-244 84 -244 16 0 240 69 340 104 485 172 1035 457 1558 808 646 431 1331
1045 1728 1547 457 577 728 1165 817 1766 35 244 20 616 -33 777 -14 43 -19
48 -62 62 -93 31 -228 47 -407 51 -99 2 -198 1 -220 -1z m-35 -559 l-265 -265
108 -107 107 -108 266 265 266 265 -6 -135 c-24 -525 -237 -1086 -614 -1615
-251 -353 -580 -704 -996 -1064 l-176 -152 0 695 0 696 -695 0 -696 0 149 173
c676 784 1329 1264 2027 1490 252 82 472 121 705 125 l85 2 -265 -265z m-1610
-2488 l0 -664 -102 -74 c-57 -41 -161 -114 -233 -162 l-130 -88 -5 588 -5 588
-588 5 -588 5 88 130 c48 72 121 176 162 233 l74 102 664 0 663 0 0 -663z
m-772 -643 l2 -532 -92 -52 c-158 -90 -385 -205 -558 -283 -163 -73 -460 -191
-464 -185 -2 2 -30 82 -62 178 l-59 175 -175 59 c-96 32 -176 60 -178 62 -6 4
112 301 185 464 76 169 192 399 284 559 l51 91 532 -2 531 -3 3 -531z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default SurfIcon;
