import React, { Dispatch, SetStateAction } from 'react';
import { Box, Typography, Grid, Paper, TextField, Rating, useTheme, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export interface TripReviewData {
  overallRating: number;
  qualityOfAccommodation: number;
  qualityOfSurfLessons: number;
  timingAccuracy: number;
  publicComment: string;
  privateComment: string;
  destinationRating: number;
  organizationRating: number;
}

/** Reusable rating style. */
const ratingStyles = {
  '& .MuiRating-iconFilled': {
    color: '#FF385C',
  },
  '& .MuiRating-iconEmpty': {
    color: '#ccc',
  },
};

interface TripLevelReviewStepProps {
  data: TripReviewData;
  setData: Dispatch<SetStateAction<TripReviewData>>;
}

const TripLevelReviewStep: React.FC<TripLevelReviewStepProps> = ({ data, setData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      sx={{
        p: isMobile ? 2 : 3,
        mb: 2,
        borderRadius: 3,
        boxShadow: '0 2px 8px rgba(0,0,0,0.06)',
      }}
    >
      <Typography variant={isMobile ? 'h6' : 'h5'} gutterBottom>
        Rate Your Trip Experience
      </Typography>

      {/** (A) OVERALL RATING (REQUIRED) */}
      <Box sx={{ mt: 3, mb: 4 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
          Overall Rating (required)
        </Typography>
        <Rating
          name="overallRating"
          value={data.overallRating}
          onChange={(_, newVal) =>
            setData((prev) => ({
              ...prev,
              overallRating: newVal ?? prev.overallRating,
            }))
          }
          icon={<StarIcon fontSize="inherit" />}
          emptyIcon={<StarBorderIcon fontSize="inherit" />}
          size="large" // <-- Make the stars bigger
          sx={{
            ...ratingStyles,
            fontSize: isMobile ? '2rem' : '2.5rem', // optional tweak
          }}
        />
      </Box>

      {/** (B) ACCOMMODATION & ACTIVITIES */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
          Accommodation & Activities
        </Typography>
        <Grid container spacing={2}>
          {/* Quality of Accommodation */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Accommodation Quality (optional)</Typography>
            <Rating
              name="qualityOfAccommodation"
              value={data.qualityOfAccommodation}
              onChange={(_, newVal) =>
                setData((prev) => ({
                  ...prev,
                  qualityOfAccommodation: newVal ?? prev.qualityOfAccommodation,
                }))
              }
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              sx={ratingStyles}
            />
          </Grid>

          {/* Quality of Surf Lessons */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Surf Lessons (optional)</Typography>
            <Rating
              value={data.qualityOfSurfLessons}
              onChange={(_, newVal) =>
                setData((prev) => ({
                  ...prev,
                  qualityOfSurfLessons: newVal ?? prev.qualityOfSurfLessons,
                }))
              }
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              sx={ratingStyles}
            />
          </Grid>
        </Grid>
      </Box>

      {/** (C) ORGANIZATION & TIMING */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
          Organization & Timing
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Organization (optional)</Typography>
            <Rating
              value={data.organizationRating}
              onChange={(_, newVal) =>
                setData((prev) => ({
                  ...prev,
                  organizationRating: newVal ?? prev.organizationRating,
                }))
              }
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              sx={ratingStyles}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Timing &amp; Schedule (optional)</Typography>
            <Rating
              value={data.timingAccuracy}
              onChange={(_, newVal) =>
                setData((prev) => ({
                  ...prev,
                  timingAccuracy: newVal ?? prev.timingAccuracy,
                }))
              }
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              sx={ratingStyles}
            />
          </Grid>
        </Grid>
      </Box>

      {/** (D) TRIP OVERVIEW RATINGS */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
          Additional Overview
        </Typography>
        <Grid container spacing={2}>
          {/* Destination Rating */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Destination Rating</Typography>
            <Rating
              name="destinationRating"
              value={data.destinationRating}
              onChange={(_, newVal) =>
                setData((prev) => ({
                  ...prev,
                  destinationRating: newVal ?? prev.destinationRating,
                }))
              }
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              sx={ratingStyles}
            />
          </Grid>
        </Grid>
      </Box>

      {/** (E) COMMENTS & FEEDBACK */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
          Comments &amp; Feedback
        </Typography>
        <Grid container spacing={2}>
          {/* Public Comment */}
          <Grid item xs={12}>
            <Typography variant="body2">Public Comment (visible to others)</Typography>
            <TextField
              multiline
              rows={isMobile ? 2 : 3}
              fullWidth
              value={data.publicComment}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  publicComment: e.target.value,
                }))
              }
            />
          </Grid>

          {/* Private Comment */}
          <Grid item xs={12}>
            <Typography variant="body2">Private Comment (only staff sees)</Typography>
            <TextField
              multiline
              rows={isMobile ? 2 : 3}
              fullWidth
              value={data.privateComment}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  privateComment: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default TripLevelReviewStep;
