import React, { useMemo, useState } from 'react';
import { Autocomplete, debounce, TextField, Typography } from '@mui/material';
import { Role, User } from '../../../generated/graphql';
import './ExtraInfo.scss';
import { useLazyQuery } from '@apollo/client';
import { USERS_QUERY } from '../../../graphql/users';

interface GuidesComponentProps {
  guides: User[];
  onChange: (newGuides: User[]) => void;
}
const GuidesFilter: React.FC<GuidesComponentProps> = ({ guides, onChange }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(guides);
  const [fetchUsers, { loading }] = useLazyQuery(USERS_QUERY, {
    onCompleted: (data) => {
      if (data?.users?.edges) {
        const newOptions = data.users.edges.map(({ node }: { node: User }) => node);
        setOptions(newOptions);
      }
    },
  });
  // Debounce function to delay API calls
  const debouncedFetch = useMemo(
    () =>
      debounce((query: string) => {
        const excludeUserIds = selectedOptions.map((user) => user.id);
        onChange(selectedOptions);
        fetchUsers({ variables: { filter: { roles: [Role.Guide], query, excludeUserIds } } });
      }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchUsers, selectedOptions]
  );
  // Handle input change
  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    if (value) {
      debouncedFetch(value);
    } else {
      setOptions([]); // Clear options when input is empty
    }
  };
  const handleValueChange = (newGuides: User[]) => {
    setSelectedOptions(newGuides);
    onChange(newGuides);
  };
  // TODO:
  // nacitat guide-ov ked je to edit
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Guides
      </Typography>
      <Autocomplete
        value={selectedOptions}
        multiple
        options={options}
        getOptionLabel={(option: User) => `${option.firstname} ${option.lastname}`}
        onChange={(_, value) => handleValueChange(value)}
        onInputChange={handleInputChange}
        loading={loading}
        renderInput={(params) => <TextField {...params} label="Search Users" variant="outlined" />}
      />
    </div>
  );
};
export default GuidesFilter;
