import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Alert, Container, Typography } from '@mui/material';
import TripReviewWizard from '../../components/TripReview/TripReviewWizard';
import { TRIP_QUERY } from '../../graphql/trips';
import { DateTime } from 'luxon';

const TripReviewPage: React.FC = () => {
  const { tripId } = useParams<{ tripId: string }>();
  const navigate = useNavigate();

  // 1) Query the trip + guides
  const { data, loading, error } = useQuery(TRIP_QUERY, {
    variables: { id: tripId },
    skip: !tripId,
    fetchPolicy: 'network-only',
  });

  if (!tripId) {
    return <Alert severity="error">No tripId provided.</Alert>;
  }

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const trip = data?.trip; // adjust structure to your actual query
  if (!trip) {
    return <Alert severity="error">Trip not found.</Alert>;
  }

  if (DateTime.fromISO(trip.endDate) > DateTime.now()) {
    return <Alert severity="info">Trip hasn't ended yet!</Alert>;
  }

  // 2) The wizard
  const handleCloseWizard = () => {
    // e.g. navigate back to membership dashboard
    navigate('/membership-dashboard');
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Leave a Review for {trip.title}
      </Typography>
      <TripReviewWizard trip={trip} guides={trip.guides || []} onClose={handleCloseWizard} />
    </Container>
  );
};

export default TripReviewPage;
