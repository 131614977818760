export const loyaltyLevels = [
  {
    title: 'LEVEL_0',
    exp: '0 - 199',
    expStart: 0,
    expEnd: 199,
    benefits: ['Access to trips', 'Community events participation'],
    icon: '/images/loyalty/level1.webp',
  },
  {
    title: 'LEVEL_1',
    exp: '200 - 499',
    expStart: 200,
    expEnd: 499,
    benefits: ['5% discount on surf camps', 'Priority booking for events'],
    icon: '/images/loyalty/level2.webp',
  },
  {
    title: 'LEVEL_2',
    exp: '500 - 999',
    expStart: 500,
    expEnd: 999,
    benefits: ['8% discount on surf camps', 'Exclusive merchandise'],
    icon: '/images/loyalty/level3.webp',
  },
  {
    title: 'LEVEL_3',
    exp: '1000 - 1999',
    expStart: 1000,
    expEnd: 1999,
    benefits: ['10% discount on surf camps', 'VIP event access'],
    icon: '/images/loyalty/level4.webp',
  },
  {
    title: 'LEVEL_4',
    exp: '2000+',
    expStart: 2000,
    expEnd: 9999,
    benefits: ['15% discount on surf camps', 'Legendary status perks'],
    icon: '/images/loyalty/level5.webp',
  },
];

export const LoyaltyLevelToName = {
  LEVEL_0: 'Kook',
  LEVEL_1: 'White Water Rider',
  LEVEL_2: 'Shredder',
  LEVEL_3: 'Lineup Local',
  LEVEL_4: 'Barrel Hunter',
};

export const earnExpMethods = [
  { activity: 'Booking a surf camp', exp: '+70 EXP' },
  { activity: 'Referring a friend', exp: '+60 EXP' },
  { activity: 'Writing a review', exp: '+20 EXP' },
  { activity: 'Sharing on social media', exp: '+20 EXP' },
  // { activity: 'Early booking', exp: '+30 EXP' },
  { activity: 'Completing a survey', exp: '+30 EXP' },
  { activity: 'Attending an event', exp: '+20 EXP' },
  // { activity: 'Anniversary bonus', exp: '+40 EXP' },
];
