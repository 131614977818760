import { Box } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import './ImageCarousel.scss';
import SliderArrow from './SliderArrow';

interface ImageSliderProps {
  images: string[];
}

const ImageSlider = ({ images }: ImageSliderProps) => {
  const renderArrowPrev = (onClickHandler: () => void, hasPrev: boolean) =>
    hasPrev && <SliderArrow onClickHandler={onClickHandler} direction="left" />;

  const renderArrowNext = (onClickHandler: () => void, hasNext: boolean) =>
    hasNext && <SliderArrow onClickHandler={onClickHandler} direction="right" />;

  return (
    <>
      <Carousel
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={renderArrowPrev}
        renderArrowNext={renderArrowNext}
        infiniteLoop
        useKeyboardArrows
        autoPlay={false}
        interval={3000}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: '100%',
              aspectRatio: '16 / 9',
              overflow: 'hidden',
              borderRadius: '16px',
              maxHeight: '200px',
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: '16px',
              }}
            />
          </Box>
        ))}
      </Carousel>
    </>
  );
};

export default ImageSlider;
