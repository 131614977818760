import { Box, Typography, useTheme } from '@mui/material';
import { Trip } from '../../generated/graphql';
import { DateTime } from 'luxon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useMediaQuery } from '@mui/system';

interface TripDescriptionProps {
  trip: Trip;
}

const DateRange = ({ trip }: TripDescriptionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <CalendarMonthIcon fontSize={isMobile ? 'medium' : 'large'} sx={{ mr: 1, color: 'text.secondary' }} />
      <Typography variant={isMobile ? 'subtitle1' : 'h6'} color="text.secondary">
        {DateTime.fromISO(trip.startDate).toFormat('dd.MM.yyyy')} -{' '}
        {DateTime.fromISO(trip.endDate).toFormat('dd.MM.yyyy')}
      </Typography>
    </Box>
  );
};

export default DateRange;
