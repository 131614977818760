import React from 'react';
import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Trip } from '../../generated/graphql';
import TripSummary from './TripSummary';
import BookNowSection from './BookNow/BookNowSection';
import DOMPurify from 'dompurify';

import BackButton from '../common/BackButton';
import DateRange from './DateRange';
import GuidesSection from './GuidesSection';
import ItinerarySection from './ItinerarySection';
import ExtraInformationSection from './ExtraInformation/ExtraInformationSection';
import { useTranslation } from 'react-i18next';
import IncludedAndExcludedServices from './IncludedAndExcludedServices/IncludedAndExcludedServices';
import ImageGallery from './ImageGallery/ImageGallery';

interface TripDetailProps {
  trip: Trip;
}

const TripDetail: React.FC<TripDetailProps> = ({ trip }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sanitizedDescription = DOMPurify.sanitize(trip?.description ?? '');

  return (
    <Container>
      <BackButton />
      <ImageGallery images={trip.images || []} />
      <TripSummary trip={trip} />
      <Typography variant={isMobile ? 'h4' : 'h3'} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {trip.title}
      </Typography>
      <DateRange trip={trip} />

      <Box
        component="div"
        sx={{
          fontSize: isMobile ? '12px' : '16px',
          lineHeight: isMobile ? 1.2 : 1.4,
          marginBottom: isMobile ? '10px' : '16px',
          '& p': {
            marginBottom: theme.spacing(1),
            color: 'rgba(0, 0, 0, 0.6)',
          },
          '& ul': {
            paddingLeft: theme.spacing(4),
            marginBottom: theme.spacing(0.5),
            color: 'rgba(0, 0, 0, 0.6)',
          },
          '& li': {
            marginBottom: theme.spacing(0.5),
            color: 'rgba(0, 0, 0, 0.6)',
          },
          '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'none',
            },
          },
          '& img': {
            maxWidth: '100%',
            height: 'auto',
            borderRadius: theme.shape.borderRadius,
            marginTop: theme.spacing(2),
          },
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
      />
      <GuidesSection guides={trip?.guides || []} />
      <IncludedAndExcludedServices
        includedServices={trip?.includedServices || []}
        excludedServices={trip?.excludedServices || []}
      />
      {trip?.itinerary && trip?.itinerary?.length > 0 && (
        <>
          <Typography variant="h4" mt={4} mb={2} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            {t('itinerary')}
          </Typography>
          <ItinerarySection itinerary={trip?.itinerary || []} />
        </>
      )}
      {trip?.extraInformation && trip?.extraInformation?.length > 0 && (
        <>
          <Typography variant="h4" mt={5} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            {t('extra_information')}
          </Typography>
          <ExtraInformationSection extraInformation={trip?.extraInformation || []} />
        </>
      )}

      <BookNowSection trip={trip} />
    </Container>
  );
};

export default TripDetail;
