import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

interface ServiceListProps {
  title: string;
  services: string[];
  emoji: string;
}

const ServiceList = ({ title, services, emoji }: ServiceListProps) => {
  return (
    <Box width="100%" borderRadius={4} display="flex" justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', sm: '80%' }} px={{ xs: 3, sm: 0 }} textAlign="left">
        <Typography variant="h6" pb={2} sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        {services.map((service) => (
          <Box key={service} my={1}>
            <Typography component="span" mr={1}>
              {emoji === 'included' ? (
                <CheckIcon fontSize="medium" sx={{ color: 'rgb(36, 209, 70)' }} />
              ) : (
                <CloseIcon fontSize="medium" sx={{ color: 'red' }} />
              )}
            </Typography>
            <Typography component="span">{service}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ServiceList;
