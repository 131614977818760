import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Avatar,
  TextField,
  Autocomplete,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Typography,
  Snackbar,
  Alert,
  Box,
  CircularProgress,
} from '@mui/material';
import { useMutation } from '@apollo/client';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import {
  SocialMediaLink,
  SocialMediaType,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from '../../generated/graphql';
import ImageUpload from '../ImageUpload/ImageUpload';

import iso6391 from 'iso-639-1';
import countryList from 'country-list';
import { getTimeZones } from '@vvo/tzdb';

// If you have an external file for constants, you can import from there instead:
const countryOptions = countryList.getData(); // array of { name, code, ...}

const ProfileEditModal: React.FC<{
  open: boolean;
  onClose: () => void;
  user?: User | null;
}> = ({ open, onClose, user }) => {
  // 1) We gather all the possible ISO639-1 codes.
  const allLanguages = iso6391.getAllCodes().map((code) => ({
    label: iso6391.getName(code), // e.g. "English"
    value: code, // e.g. "en"
  }));

  // 2) All timezones
  const allTz = getTimeZones();

  // 3) The user’s form state
  const [formData, setFormData] = useState({
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    phone: user?.phone || '',
    birthDate: user?.birthDate || '',
    permanentAddress: user?.permanentAddress || '',
    contactAddress: user?.contactAddress || '',
    isEmailSubscribed: user?.isEmailSubscribed ?? true,
    position: user?.position || '',
    description: user?.description || '',
    countriesVisited: user?.countriesVisited || [],
    languagesSpoken: user?.languagesSpoken || [],
    socialMediaLinks: user?.socialMediaLinks || ([] as SocialMediaLink[]),

    country: user?.country || 'SK', // main country
    city: user?.city || 'Bratislava', // free text
    timezone: user?.timezone || 'Europe/Bratislava',
    preferredLanguage: user?.preferredLanguage || 'en',
  });

  const [imageUrl, setImageUrl] = useState(user?.imageUrl || null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, {
    onCompleted: () => {
      onClose();
    },
    onError: (err) => {
      console.error('Error updating profile:', err);
      setError('Error updating profile. Please try again.');
    },
  });

  // whenever user changes (in case of re-opening the modal), reset the form
  useEffect(() => {
    if (user) {
      setFormData({
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        phone: user.phone || '',
        birthDate: user.birthDate || '',
        permanentAddress: user.permanentAddress || '',
        contactAddress: user.contactAddress || '',
        isEmailSubscribed: user.isEmailSubscribed ?? true,
        position: user.position || '',
        description: user.description || '',
        countriesVisited: user.countriesVisited || [],
        languagesSpoken: user.languagesSpoken || [],
        socialMediaLinks: user.socialMediaLinks || [],
        country: user.country || 'US',
        city: user.city || '',
        timezone: user.timezone || 'America/New_York',
        preferredLanguage: user.preferredLanguage || 'en',
      });
      setImageUrl(user.imageUrl || null);
    }
  }, [user]);

  // For text fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // For toggle fields
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  // Social links
  const handleSocialLinkChange = (index: number, field: 'type' | 'url', value: string) => {
    const updated = [...formData.socialMediaLinks];
    updated[index] = { ...updated[index], [field]: field === 'type' ? (value as SocialMediaType) : value };
    setFormData((prev) => ({ ...prev, socialMediaLinks: updated }));
  };
  const addSocialLinkField = () => {
    setFormData((prev) => ({
      ...prev,
      socialMediaLinks: [...prev.socialMediaLinks, { type: SocialMediaType.Facebook, url: '' }],
    }));
  };
  const removeSocialLinkField = (index: number) => {
    const updated = [...formData.socialMediaLinks];
    updated.splice(index, 1);
    setFormData((prev) => ({ ...prev, socialMediaLinks: updated }));
  };
  const hasDuplicateSocialMediaTypes = (links: SocialMediaLink[]) => {
    const types = links.map((l) => l.type);
    return types.length !== new Set(types).size;
  };

  // For country => we’ll auto update the timezone if the old tz is not in new country’s list
  const handleCountryChange = (newCountryCode: string) => {
    setFormData((prev) => {
      const possibleTzs = allTz.filter((tz) => tz.countryCode === newCountryCode);
      if (!possibleTzs.length) {
        // fallback to entire list or some default
        return { ...prev, country: newCountryCode, timezone: 'UTC' };
      }
      // check if current tz is valid
      const currentTzValid = possibleTzs.some((tzObj) => tzObj.name === prev.timezone);
      return {
        ...prev,
        country: newCountryCode,
        timezone: currentTzValid ? prev.timezone : possibleTzs[0].name,
      };
    });
  };

  // For timezone
  const getPossibleTimezones = () => {
    const tzs = allTz.filter((tzObj) => tzObj.countryCode === formData.country);
    return tzs.length ? tzs : allTz;
  };

  // Save
  const handleSave = async () => {
    // check duplicates
    if (hasDuplicateSocialMediaTypes(formData.socialMediaLinks)) {
      setError('Please remove duplicate social media platforms before saving');
      return;
    }
    setIsSaving(true);
    try {
      // Flatten social links
      const cleanedLinks = formData.socialMediaLinks.map(({ type, url }) => ({ type, url }));
      await updateUser({
        variables: {
          data: {
            firstname: formData.firstname,
            lastname: formData.lastname,
            phone: formData.phone,
            birthDate: formData.birthDate,
            permanentAddress: formData.permanentAddress,
            contactAddress: formData.contactAddress,
            isEmailSubscribed: formData.isEmailSubscribed,
            position: formData.position,
            description: formData.description,
            countriesVisited: formData.countriesVisited,
            languagesSpoken: formData.languagesSpoken,
            socialMediaLinks: cleanedLinks,
            imageUrl,

            // new fields
            country: formData.country,
            city: formData.city,
            timezone: formData.timezone,
            preferredLanguage: formData.preferredLanguage,
          },
        },
      });
    } catch (e) {
      console.error(e);
      setError('Error updating profile. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* Profile Avatar */}
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Avatar
                src={imageUrl || '/images/default_avatar.png'}
                alt="Profile Image"
                sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
              />
              <ImageUpload onUploadSuccess={(uploadedImageUrl) => setImageUrl(uploadedImageUrl)} />
            </Grid>

            {/* firstname/lastname */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>

            {/* phone/birthDate */}
            <Grid item xs={12} sm={6}>
              <TextField label="Phone" name="phone" value={formData.phone} onChange={handleInputChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Birth Date"
                name="birthDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formData.birthDate}
                onChange={handleInputChange}
              />
            </Grid>

            {/* addresses */}
            <Grid item xs={12}>
              <TextField
                label="Permanent Address"
                name="permanentAddress"
                value={formData.permanentAddress}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contact Address"
                name="contactAddress"
                value={formData.contactAddress}
                onChange={handleInputChange}
                fullWidth
                helperText="Optional"
              />
            </Grid>

            {/* subscription toggle */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch checked={formData.isEmailSubscribed} onChange={handleSwitchChange} name="isEmailSubscribed" />
                }
                label="Receive email notifications"
              />
            </Grid>

            {/* position (guide title) + about me */}
            <Grid item xs={12}>
              <TextField
                label="Position / Title"
                name="position"
                value={formData.position}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="About Me"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>

            {/* languagesSpoken (multi) */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={allLanguages}
                getOptionLabel={(option) => option.label}
                value={allLanguages.filter((l) => formData.languagesSpoken.includes(l.value))}
                onChange={(event, newVal) => {
                  setFormData((prev) => ({
                    ...prev,
                    languagesSpoken: newVal.map((item) => item.value),
                  }));
                }}
                renderInput={(params) => <TextField {...params} label="Languages Spoken" />}
              />
            </Grid>

            {/* countriesVisited (multi) */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={countryOptions}
                getOptionLabel={(c) => c.name}
                value={countryOptions.filter((c) => formData.countriesVisited.includes(c.code))}
                onChange={(event, newVal) => {
                  setFormData((prev) => ({
                    ...prev,
                    countriesVisited: newVal.map((c) => c.code),
                  }));
                }}
                renderInput={(params) => <TextField {...params} label="Countries Visited" />}
              />
            </Grid>

            {/* Single "main" country */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Home Country</InputLabel>
                <Select
                  value={formData.country}
                  label="Home Country"
                  onChange={(e) => handleCountryChange(e.target.value)}
                >
                  {countryOptions.map((c) => (
                    <MenuItem key={c.code} value={c.code}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* City */}
            <Grid item xs={12} sm={6}>
              <TextField label="City" name="city" value={formData.city} onChange={handleInputChange} fullWidth />
            </Grid>

            {/* Timezone */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Timezone</InputLabel>
                <Select
                  value={formData.timezone}
                  label="Timezone"
                  onChange={(e) => setFormData({ ...formData, timezone: e.target.value })}
                >
                  {getPossibleTimezones().map((tz) => (
                    <MenuItem key={tz.name} value={tz.name}>
                      {tz.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Preferred Language */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Preferred Language</InputLabel>
                <Select
                  label="Preferred Language"
                  value={formData.preferredLanguage}
                  onChange={(e) => setFormData({ ...formData, preferredLanguage: e.target.value })}
                >
                  {allLanguages.map((lang) => (
                    <MenuItem key={lang.value} value={lang.value}>
                      {lang.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Social links */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  Social Links
                </Typography>
                <Tooltip title="Add Social Link">
                  <IconButton onClick={addSocialLinkField} size="small">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              {formData.socialMediaLinks.map((link, idx) => (
                <Grid container spacing={1} key={idx} sx={{ mb: 1 }}>
                  <Grid item xs={5}>
                    <TextField
                      select
                      label="Platform"
                      value={link.type}
                      onChange={(e) => handleSocialLinkChange(idx, 'type', e.target.value)}
                      fullWidth
                    >
                      {Object.values(SocialMediaType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="URL"
                      value={link.url}
                      onChange={(e) => handleSocialLinkChange(idx, 'url', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => removeSocialLinkField(idx)}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <Button onClick={onClose} color="secondary" disabled={isSaving}>
            Cancel
          </Button>
          <Box sx={{ position: 'relative' }}>
            <Button color="primary" variant="contained" onClick={handleSave} disabled={isSaving} sx={{ minWidth: 120 }}>
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
            {isSaving && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.main',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  mt: '-12px',
                  ml: '-12px',
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfileEditModal;
