import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '@mui/material';
import { ExtraInfo } from '../../../generated/graphql';

interface ExtraInformationProps {
  item: ExtraInfo;
  icon: React.ReactNode;
}

export default function ExtraInformation({ item, icon }: ExtraInformationProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box key={item.id} display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h6" mt={2} sx={{ textAlign: 'center' }}>
        {item.title}
      </Typography>
      <Typography component="div" variant="body1" py={2} sx={{ textAlign: 'center' }}>
        <Box
          sx={{
            fontSize: isMobile ? '12px' : '16px',
          }}
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </Typography>
      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        <Divider sx={{ width: '350px', color: 'secondary.main', borderBottomWidth: '45px', height: '50px' }}>
          <Box
            display="flex"
            color="white"
            bgcolor="secondary.main"
            borderRadius="50%"
            width="50px"
            height="50px"
            alignItems="center"
            justifyContent="center"
          >
            {icon}
          </Box>
        </Divider>
      </Box>
    </Box>
  );
}
