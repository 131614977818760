import { Stack } from '@mui/system';
import { ExtraInfo } from '../../../generated/graphql';
import ExtraInformation from './ExtraInformation';
import CardTravel from '@mui/icons-material/CardTravel';
import Info from '@mui/icons-material/Info';
import Payments from '@mui/icons-material/Payments';
import Vaccines from '@mui/icons-material/Vaccines';
import WbSunny from '@mui/icons-material/WbSunny';
import { ReactElement } from 'react';
import PassportIcon from '../Icons/PassportIcon';
import TimezoneIcon from '../Icons/TimezoneIcon';
import ApprovalOutlined from '@mui/icons-material/ApprovalOutlined';
interface ExtraInformationSectionProps {
  extraInformation: ExtraInfo[];
}

const iconMapping: { [key: string]: ReactElement } = {
  'visa info': <ApprovalOutlined fontSize="large" />,
  'travel insurance': <CardTravel fontSize="large" />,
  'passport info': <PassportIcon size={34} />,
  vaccination: <Vaccines fontSize="large" />,
  money: <Payments fontSize="large" />,
  'time zone': <TimezoneIcon size={34} />,
  weather: <WbSunny fontSize="large" />,
  'extra info': <Info fontSize="large" />,
};
export default function ExtraInformationSection({ extraInformation }: ExtraInformationSectionProps) {
  return (
    <Stack direction="column" alignItems="center" mb={4}>
      {extraInformation?.map((item) => (
        <ExtraInformation key={item.id} item={item} icon={iconMapping[item.title.toLowerCase()]} />
      ))}
    </Stack>
  );
}
