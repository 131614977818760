import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CircularProgress, Alert, Snackbar, MenuItem, Menu } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { useDebounce } from 'use-debounce';
import { USERS_QUERY, UPDATE_USER_ROLES_MUTATION, DELETE_USER_MUTATION, RoleAction } from '../../../../graphql/users';
import { UPDATE_MEMBERSHIP_STATUS_MUTATION, DELETE_MEMBERSHIP_MUTATION } from '../../../../graphql/membership';
import {
  User,
  Role,
  UsersQuery,
  UsersQueryVariables,
  UpdateUserRolesMutation,
  UpdateUserRolesMutationVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  UpdateMembershipStatusMutation,
  UpdateMembershipStatusMutationVariables,
  MembershipStatusEnum,
  DeleteMembershipMutation,
  DeleteMembershipMutationVariables,
} from '../../../../generated/graphql';
import MembershipReviewModal from '../../../Memberships/MembershipReviewModal';
import SearchBar from '../SearchBar/SearchBar';
import UsersTable from '../UsersTable/UsersTable';
import ManageRolesDialog from '../ManageRolesDialog/ManageRolesDialog';
import DeleteUserDialog from '../DeleteUserDialog/DeleteUserDialog';
import MembershipActionDialog from '../MembershipActionDialog/MembershipActionDialog';
import DeleteMembershipDialog from '../DeleteMembershipDialog/DeleteMembershipDialog';

const AllUsersTab: React.FC = () => {
  // State variables
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearchInput] = useDebounce(searchInput, 300);
  const searchInputRef = useRef<HTMLInputElement>(null);

  // Variables for pagination
  const [queryVariables, setQueryVariables] = useState<UsersQueryVariables>({
    first: rowsPerPage,
    after: null,
    filter: {
      query: debouncedSearchInput,
    },
  });

  // Data fetching using useQuery
  const { data, loading, error, refetch } = useQuery<UsersQuery, UsersQueryVariables>(USERS_QUERY, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  // Mutations
  const [updateUserRoles] = useMutation<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>(
    UPDATE_USER_ROLES_MUTATION
  );
  const [deleteUser] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DELETE_USER_MUTATION);
  const [updateMembershipStatus] = useMutation<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>(
    UPDATE_MEMBERSHIP_STATUS_MUTATION
  );
  const [deleteMembership] = useMutation<DeleteMembershipMutation, DeleteMembershipMutationVariables>(
    DELETE_MEMBERSHIP_MUTATION
  );

  // State for dialogs and selected user
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isChangeRolesDialogOpen, setIsChangeRolesDialogOpen] = useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);
  const [newRoles, setNewRoles] = useState<Role[]>([]);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);

  // Snackbar state for feedback messages
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  // New state variables
  const [selectedMembershipUser, setSelectedMembershipUser] = useState<User | null>(null);
  const [isMembershipReviewModalOpen, setIsMembershipReviewModalOpen] = useState(false);
  const [isMembershipActionDialogOpen, setIsMembershipActionDialogOpen] = useState(false);
  const [membershipActionType, setMembershipActionType] = useState<'activate' | 'deactivate'>('deactivate');
  const [isDeleteMembershipDialogOpen, setIsDeleteMembershipDialogOpen] = useState(false);

  // Search input change handler
  const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  }, []);

  // Effect to reset pagination when debounced search input changes
  useEffect(() => {
    setPage(0);
    const variables: UsersQueryVariables = {
      first: rowsPerPage,
      after: null,
      filter: {
        query: debouncedSearchInput,
      },
      // Ensure 'last' and 'before' are undefined
      last: undefined,
      before: undefined,
    };
    setQueryVariables(variables);
    refetch(variables);
  }, [debouncedSearchInput, rowsPerPage, refetch]);

  // Handle change page
  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      if (!data?.users) return;

      if (newPage > page && data.users.pageInfo.hasNextPage) {
        // Forward pagination: Set 'first' and 'after', ensure 'last' and 'before' are undefined
        const variables: UsersQueryVariables = {
          first: rowsPerPage,
          after: data.users.pageInfo.endCursor,
          filter: {
            query: debouncedSearchInput,
          },
          last: undefined,
          before: undefined,
        };
        setQueryVariables(variables);
        refetch(variables);
      } else if (newPage < page && data.users.pageInfo.hasPreviousPage) {
        // Backward pagination: Set 'last' and 'before', ensure 'first' and 'after' are undefined
        const variables: UsersQueryVariables = {
          last: rowsPerPage,
          before: data.users.pageInfo.startCursor,
          filter: {
            query: debouncedSearchInput,
          },
          first: undefined,
          after: undefined,
        };
        setQueryVariables(variables);
        refetch(variables);
      }
      setPage(newPage);
    },
    [page, rowsPerPage, refetch, data, debouncedSearchInput]
  );

  // Handle change rows per page
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(newRowsPerPage);
      setPage(0);

      const variables: UsersQueryVariables = {
        first: newRowsPerPage,
        after: null,
        filter: {
          query: debouncedSearchInput,
        },
        last: undefined,
        before: undefined,
      };
      setQueryVariables(variables);
      refetch(variables);
    },
    [refetch, debouncedSearchInput]
  );

  // Handle actions menu
  const handleActionsMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>, user: User) => {
    setActionsAnchorEl(event.currentTarget);
    setSelectedUser(user);
  }, []);

  const handleActionsMenuClose = useCallback(() => {
    setActionsAnchorEl(null);
  }, []);

  const handleOpenChangeRolesDialog = useCallback(() => {
    if (selectedUser) {
      setNewRoles(selectedUser.roles || []);
      setIsChangeRolesDialogOpen(true);
    }
    handleActionsMenuClose();
  }, [selectedUser, handleActionsMenuClose]);

  const handleCloseChangeRolesDialog = useCallback(() => {
    setIsChangeRolesDialogOpen(false);
  }, []);

  const handleToggleRole = useCallback(
    async (role: Role) => {
      if (selectedUser) {
        const userId = selectedUser.id;
        const hasRole = newRoles.includes(role);
        const action = hasRole ? RoleAction.REMOVE : RoleAction.ADD;

        try {
          await updateUserRoles({
            variables: { id: userId, role, action },
          });

          // Update local state
          if (action === RoleAction.ADD) {
            setNewRoles((prevRoles) => [...prevRoles, role]);
          } else {
            setNewRoles((prevRoles) => prevRoles.filter((r) => r !== role));
          }

          setSnackbarMessage(`Role ${action === RoleAction.ADD ? 'added' : 'removed'}.`);
          setSnackbarOpen(true);
          refetch(queryVariables);
        } catch (error) {
          console.error(error);
          setSnackbarMessage(`Error updating role.`);
          setSnackbarOpen(true);
        }
      }
    },
    [selectedUser, newRoles, updateUserRoles, refetch, queryVariables]
  );

  const handleDeleteUser = useCallback(() => {
    setIsDeleteUserDialogOpen(true);
    handleActionsMenuClose();
  }, [handleActionsMenuClose]);

  const handleCloseDeleteUserDialog = useCallback(() => {
    setIsDeleteUserDialogOpen(false);
  }, []);

  const handleConfirmDeleteUser = useCallback(async () => {
    if (selectedUser) {
      try {
        await deleteUser({
          variables: { id: selectedUser.id },
        });
        setSnackbarMessage('User deleted.');
        setSnackbarOpen(true);
        refetch(queryVariables);
      } catch (error) {
        console.error(error);
        setSnackbarMessage('Error deleting user.');
        setSnackbarOpen(true);
      }
      handleCloseDeleteUserDialog();
    }
  }, [selectedUser, deleteUser, refetch, queryVariables, handleCloseDeleteUserDialog]);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  // Handlers for membership actions
  const handleAcceptMembership = useCallback(
    async (userId: string) => {
      try {
        await updateMembershipStatus({
          variables: { userId, status: MembershipStatusEnum.Active },
        });
        setSnackbarMessage('Membership accepted.');
        setSnackbarOpen(true);
        refetch(queryVariables);
      } catch (error) {
        console.error(error);
        setSnackbarMessage('Error accepting membership.');
        setSnackbarOpen(true);
      }
    },
    [updateMembershipStatus, refetch, queryVariables]
  );

  const handleRejectMembership = useCallback(
    async (userId: string) => {
      try {
        await updateMembershipStatus({
          variables: { userId, status: MembershipStatusEnum.Rejected },
        });
        setSnackbarMessage('Membership rejected.');
        setSnackbarOpen(true);
        refetch(queryVariables);
      } catch (error) {
        console.error(error);
        setSnackbarMessage('Error rejecting membership.');
        setSnackbarOpen(true);
      }
    },
    [updateMembershipStatus, refetch, queryVariables]
  );

  const handleOpenMembershipReviewModal = useCallback((user: User) => {
    setSelectedMembershipUser(user);
    setIsMembershipReviewModalOpen(true);
  }, []);

  const handleCloseMembershipReviewModal = useCallback(() => {
    setIsMembershipReviewModalOpen(false);
    setSelectedMembershipUser(null);
  }, []);

  const handleOpenMembershipActionDialog = useCallback((user: User, action: 'activate' | 'deactivate') => {
    setSelectedMembershipUser(user);
    setMembershipActionType(action);
    setIsMembershipActionDialogOpen(true);
  }, []);

  const handleCloseMembershipActionDialog = useCallback(() => {
    setIsMembershipActionDialogOpen(false);
    setSelectedMembershipUser(null);
  }, []);

  const handleConfirmMembershipAction = useCallback(async () => {
    if (selectedMembershipUser) {
      const userId = selectedMembershipUser.id;
      const status = membershipActionType === 'activate' ? MembershipStatusEnum.Active : MembershipStatusEnum.Rejected;

      try {
        await updateMembershipStatus({
          variables: { userId, status },
        });
        setSnackbarMessage(`Membership ${membershipActionType}d.`);
        setSnackbarOpen(true);
        refetch(queryVariables);
      } catch (error) {
        console.error(error);
        setSnackbarMessage(`Error ${membershipActionType}ing membership.`);
        setSnackbarOpen(true);
      }
      handleCloseMembershipActionDialog();
    }
  }, [
    selectedMembershipUser,
    membershipActionType,
    updateMembershipStatus,
    refetch,
    queryVariables,
    handleCloseMembershipActionDialog,
  ]);

  const handleCloseDeleteMembershipDialog = useCallback(() => {
    setIsDeleteMembershipDialogOpen(false);
  }, []);

  const handleDeleteMembership = useCallback(() => {
    setIsDeleteMembershipDialogOpen(true);
    handleActionsMenuClose();
  }, [handleActionsMenuClose]);

  const handleConfirmDeleteMembership = useCallback(async () => {
    if (selectedUser) {
      try {
        await deleteMembership({
          variables: { userId: selectedUser.id },
        });
        setSnackbarMessage('Membership deleted.');
        setSnackbarOpen(true);
        refetch(queryVariables);
        handleCloseDeleteMembershipDialog();
      } catch (error) {
        console.error(error);
        setSnackbarMessage('Error deleting membership.');
        setSnackbarOpen(true);
        handleCloseDeleteMembershipDialog();
      }
    }
  }, [selectedUser, deleteMembership, refetch, queryVariables, handleCloseDeleteMembershipDialog]);

  if (loading) return <CircularProgress sx={{ display: 'flex', justifyContent: 'center', mt: 4 }} />;

  if (error) {
    console.error(error.message);
    return <Alert severity="error">Error loading data.</Alert>;
  }

  return (
    <>
      {/* Search Panel */}
      <SearchBar
        searchInput={searchInput}
        onSearchInputChange={handleSearchInputChange}
        searchInputRef={searchInputRef}
      />

      {/* Users Table */}
      <UsersTable
        usersData={data}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleActionsMenuOpen={handleActionsMenuOpen}
        handleOpenMembershipReviewModal={handleOpenMembershipReviewModal}
        handleOpenMembershipActionDialog={handleOpenMembershipActionDialog}
      />

      {/* Actions Menu */}
      <Menu anchorEl={actionsAnchorEl} open={Boolean(actionsAnchorEl)} onClose={handleActionsMenuClose}>
        <MenuItem onClick={handleOpenChangeRolesDialog}>Manage Roles</MenuItem>
        <MenuItem onClick={handleDeleteUser}>Delete User</MenuItem>
        <MenuItem onClick={handleDeleteMembership}>Delete Membership</MenuItem>
      </Menu>

      {/* Manage Roles Dialog */}
      <ManageRolesDialog
        isOpen={isChangeRolesDialogOpen}
        onClose={handleCloseChangeRolesDialog}
        newRoles={newRoles}
        handleToggleRole={handleToggleRole}
      />

      {/* Delete User Dialog */}
      <DeleteUserDialog
        isOpen={isDeleteUserDialogOpen}
        onClose={handleCloseDeleteUserDialog}
        handleConfirmDeleteUser={handleConfirmDeleteUser}
      />

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />

      {/* Membership Review Modal */}
      {selectedMembershipUser && (
        <MembershipReviewModal
          open={isMembershipReviewModalOpen}
          user={selectedMembershipUser}
          onClose={handleCloseMembershipReviewModal}
          onAccept={() => {
            handleAcceptMembership(selectedMembershipUser.id);
            handleCloseMembershipReviewModal();
          }}
          onReject={() => {
            handleRejectMembership(selectedMembershipUser.id);
            handleCloseMembershipReviewModal();
          }}
        />
      )}

      {/* Membership Action Dialog */}
      {selectedMembershipUser && (
        <MembershipActionDialog
          isOpen={isMembershipActionDialogOpen}
          onClose={handleCloseMembershipActionDialog}
          onConfirm={handleConfirmMembershipAction}
          action={membershipActionType}
          userName={`${selectedMembershipUser.firstname} ${selectedMembershipUser.lastname}`}
        />
      )}

      {/* Delete Membership Dialog */}
      <DeleteMembershipDialog
        isOpen={isDeleteMembershipDialogOpen}
        onClose={handleCloseDeleteMembershipDialog}
        handleConfirmDeleteMembership={handleConfirmDeleteMembership}
      />
    </>
  );
};

export default AllUsersTab;
