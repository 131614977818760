import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT } from './users';

export const MEMBERSHIP_REQUEST_MUTATION = gql`
  mutation MembershipRequest($data: CreateMembershipInput!) {
    membershipRequest(data: $data) {
      id
      status
      firstname
      lastname
      birthDate
      permanentAddress
      contactAddress
      phone
    }
  }
`;

export const SUBMIT_MEMBERSHIP_REQUEST_MUTATION = gql`
  mutation SubmitMembershipRequest {
    submitMembershipRequest {
      id
      status
      firstname
      lastname
      birthDate
      permanentAddress
      contactAddress
      phone
    }
  }
`;

export const ACCEPT_MEMBERSHIP_REQUEST_MUTATION = gql`
  mutation AcceptMembershipRequest($userId: String!) {
    acceptMembershipRequest(userId: $userId) {
      id
      status
      updatedAt
      approvedBy {
        id
        email
      }
    }
  }
`;

export const UPDATE_MEMBERSHIP_STATUS_MUTATION = gql`
  mutation UpdateMembershipStatus($userId: String!, $status: MembershipStatusEnum!) {
    updateMembershipStatus(userId: $userId, status: $status) {
      id
      status
      updatedAt
    }
  }
`;

export const MY_MEMBERSHIP_QUERY = gql`
  query MyMembership {
    myMembership {
      id
      status
      firstname
      lastname
      birthDate
      permanentAddress
      contactAddress
      phone
      createdAt
      approvedBy {
        id
        firstname
        lastname
      }
    }
  }
`;

export const MEMBERSHIPS_QUERY = gql`
  query Memberships(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: MembershipOrder
    $status: MembershipStatusEnum!
  ) {
    memberships(first: $first, after: $after, last: $last, before: $before, orderBy: $orderBy, status: $status) {
      edges {
        node {
          id
          status
          createdAt
          updatedAt
        }
      }
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const MEMBERSHIP_QUERY = gql`
  query Membership($userId: String!) {
    membership(userId: $userId) {
      id
      status
      createdAt
      approvedBy {
        firstname
        lastname
        email
      }
    }
  }
`;

export const DELETE_MEMBERSHIP_MUTATION = gql`
  mutation DeleteMembership($userId: String!) {
    deleteMembership(userId: $userId) {
      id
      status
    }
  }
`;

export const CANCEL_MY_MEMBERSHIP_MUTATION = gql`
  mutation CancelMyMembership {
    cancelMyMembership {
      id
      status
    }
  }
`;
