import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface CustomArrowProps {
  onClickHandler: () => void;
  direction: 'left' | 'right';
}

const arrowIconStyle = {
  color: 'black',
  fontSize: '24px',
  '&:hover': {
    opacity: 0.6,
  },
};

const SliderArrow = ({ onClickHandler, direction }: CustomArrowProps) => {
  return (
    <IconButton
      onClick={onClickHandler}
      sx={{
        position: 'absolute',
        top: '50%',
        [direction === 'left' ? 'left' : 'right']: '10px',
        transform: 'translateY(-50%)',
        zIndex: 10,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
      }}
    >
      {direction === 'left' ? <ArrowBackIosNewIcon sx={arrowIconStyle} /> : <ArrowForwardIosIcon sx={arrowIconStyle} />}
    </IconButton>
  );
};

export default SliderArrow;
