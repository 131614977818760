import React from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  useTheme,
  Box,
  IconButton,
  Button,
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Link as RouterLink } from 'react-router-dom';
import { Trip } from '../../generated/graphql';

interface TripCardProps {
  trip: Trip;
}

const TripCard: React.FC<TripCardProps> = ({ trip }) => {
  const theme = useTheme();

  const startDate = new Date(trip.startDate).toLocaleDateString();
  const endDate = new Date(trip.endDate).toLocaleDateString();

  const hasAvailability = trip.approvedParticipantsCount < trip.numberOfPeople;

  return (
    <Card
      sx={{
        maxWidth: 345,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
        '&:hover': {
          boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
          transform: 'translateY(-5px)',
        },
      }}
    >
      <CardActionArea component={RouterLink} to={`/trip/${trip.id}`} sx={{ flex: 1, position: 'relative' }}>
        <CardMedia
          component="img"
          sx={{ height: 200, objectFit: 'cover', width: '100%' }}
          image={trip.images?.[0] || '/images/default-trip.jpg'}
          alt={`Image of ${trip.title}`}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, transparent 60%)',
            bottom: 0,
            left: 0,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            padding: '4px 8px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: hasAvailability ? '#4caf50' : '#f44336',
            }}
          />
          <Typography
            variant="caption"
            sx={{
              color: 'white',
              fontWeight: 600,
            }}
          >
            {trip.approvedParticipantsCount}/{trip.numberOfPeople}
          </Typography>
        </Box>
        <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
          <IconButton sx={{ color: 'white' }}>
            <FavoriteBorderIcon />
          </IconButton>
        </Box>
        <CardContent sx={{ position: 'absolute', bottom: 10, zIndex: 2, px: 2 }}>
          <Typography
            variant="h6"
            sx={{
              color: 'white',
              fontWeight: 700,
              textShadow: '1px 1px 5px rgba(0,0,0,0.8)',
            }}
          >
            {trip.title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'white',
              textShadow: '1px 1px 3px rgba(0,0,0,0.6)',
            }}
          >
            {startDate} - {endDate}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <Typography variant="body1" fontWeight={600}>
          €{trip.price} / person
        </Typography>
        <Button
          component={RouterLink}
          to={`/trip/${trip.id}`}
          endIcon={<ArrowCircleRightIcon />}
          sx={{
            color: '#f3bc13',
            fontWeight: 600,
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#f3bc13',
            },
          }}
        >
          Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default TripCard;
