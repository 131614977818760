import { Avatar, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { User } from '../../generated/graphql';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';

interface GuideSectionProps {
  guides: User[];
}

export default function GuideSection({ guides }: GuideSectionProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={3}
      sx={{
        flexWrap: {
          xs: 'wrap',
          sm: 'wrap',
          md: 'nowrap',
        },
      }}
    >
      {guides &&
        guides?.length > 0 &&
        guides?.map((guide, index) => (
          <Box
            display="flex"
            alignItems="center"
            borderRadius={4}
            width="400px"
            height="130px"
            key={guide.id + index}
            sx={{
              backgroundImage: `url('/images/waves.svg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" p={3}>
              <Avatar
                key={guide.id}
                src={guide?.imageUrl || ''}
                sx={{ width: '80px', height: '80px', marginRight: '4px' }}
              />
              <Box
                height="80px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
                ml={2}
                borderRadius={4}
                pt={1}
                bgcolor="white"
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Link
                    to={`/user/${guide.id}`}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      display: 'flex',
                    }}
                  >
                    <Typography variant="h6" mr="4px">
                      {guide?.firstname}
                    </Typography>
                    <Typography variant="h6">{guide?.lastname}</Typography>
                  </Link>
                </Box>

                {guide.socialMediaLinks &&
                  guide.socialMediaLinks?.length > 0 &&
                  guide.socialMediaLinks.find((social) => social.type === 'INSTAGRAM') && (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Link
                        to={guide.socialMediaLinks.find((social) => social.type === 'INSTAGRAM')?.url || ''}
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                          display: 'flex',
                        }}
                      >
                        <InstagramIcon sx={{ color: 'text.secondary', marginRight: '4px' }} />

                        <Typography variant="body1" color="text.secondary">
                          Instagram
                        </Typography>
                      </Link>
                    </Box>
                  )}

                {!(
                  guide.socialMediaLinks &&
                  guide.socialMediaLinks?.length > 0 &&
                  guide.socialMediaLinks.find((social) => social.type === 'INSTAGRAM')
                ) && (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="body1" color="text.secondary">
                      Tour guide
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ))}
    </Stack>
  );
}
