import { Typography } from '@mui/material';
import { CustomButton } from '../../common/Button';

interface BookNowContentProps {
  text: string;
  buttonText: string;
  buttonHref?: string;
  isFull?: boolean;
  occupacyText?: string;
  onClick?: () => void;
}

export default function BookNowContent({
  text,
  buttonText,
  buttonHref,
  isFull,
  occupacyText,
  onClick,
}: BookNowContentProps) {
  return (
    <>
      <Typography variant="body2" mt={3}>
        {text}
      </Typography>
      {occupacyText && (
        <Typography variant="body2" mt={3} fontWeight="bold">
          {occupacyText}
        </Typography>
      )}

      <CustomButton
        text={buttonText}
        href={buttonHref}
        sx={{ my: 3, color: 'black !important' }}
        onClick={onClick}
        disabled={isFull}
      />
    </>
  );
}
