import { gql } from '@apollo/client';
import { PUBLIC_USER_DATA_FRAGMENT } from './users';
import { TRIP_EXPENSE_DATA_FRAGMENT } from './trip-expenses';

/**
 * Fragment for the new cancellation policy fields
 */
export const CANCELLATION_POLICY_DATA_FRAGMENT = gql`
  fragment CancellationPolicyData on CancellationPolicy {
    id
    baseFee
    currency
    note
    rules {
      id
      daysBeforeStart
      refundPercentage
    }
  }
`;

/**
 * Fragment for the trip data
 */
export const TRIP_DATA_FRAGMENT = gql`
  fragment TripData on Trip {
    id
    title
    description
    startDate
    endDate
    price
    numberOfPeople
    temporaryBookingLink
    approvedParticipantsCount
    continent
    country
    images
    itinerary {
      createdAt
      description
      id
      photoUrls
      updatedAt
    }
    extraInformation {
      id
      title
      description
    }
    includedServices
    excludedServices
    status
    createdAt
    updatedAt
    organizer {
      ...PublicUserData
    }
    guides {
      ...PublicUserData
    }
    isJoinable
    cancellationPolicy {
      ...CancellationPolicyData
    }
  }
  ${PUBLIC_USER_DATA_FRAGMENT}
  ${CANCELLATION_POLICY_DATA_FRAGMENT}
`;

export const CREATE_TRIP_MUTATION = gql`
  mutation CreateTrip($input: CreateTripDto!) {
    createTrip(input: $input) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const PUBLIC_TRIPS_QUERY = gql`
  query PublicTrips(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: TripOrder
    $filter: SearchPublicTripsFilter
  ) {
    publicTrips(first: $first, after: $after, last: $last, before: $before, orderBy: $orderBy, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          ...TripData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const TRIPS_QUERY = gql`
  query Trips(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: TripOrder
    $filter: SearchTripsFilter
  ) {
    trips(first: $first, after: $after, last: $last, before: $before, orderBy: $orderBy, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          ...TripData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const CHANGE_TRIP_STATUS_MUTATION = gql`
  mutation ChangeTripStatus($id: String!, $status: TripStatusEnum!) {
    changeTripStatus(id: $id, status: $status) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const TRIP_QUERY = gql`
  query Trip($id: String!) {
    trip(id: $id) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const ORGANIZER_TRIP_QUERY = gql`
  query OrganizerTrip($id: String!) {
    organizerTrip(id: $id) {
      ...TripData
      expenses {
        ...TripExpenseData
      }
    }
  }
  ${TRIP_DATA_FRAGMENT}
  ${TRIP_EXPENSE_DATA_FRAGMENT}
`;

export const UPDATE_TRIP_MUTATION = gql`
  mutation UpdateTrip($id: String!, $data: UpdateTripDto!) {
    updateTrip(id: $id, data: $data) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const UPDATE_TRIP_IS_JOINABLE_MUTATION = gql`
  mutation UpdateTripIsJoinable($tripId: String!, $isJoinable: Boolean!) {
    updateTripIsJoinable(tripId: $tripId, isJoinable: $isJoinable) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;
