import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import SurfIcon from './Icons/SurfIcon';
import { useTranslation } from 'react-i18next';
import { DayItinerary } from '../../generated/graphql';
import ImageSlider from './ImageCarousel/ImageCarousel';

interface ItinerarySectionProps {
  itinerary: DayItinerary[];
}

function ItinerarySection({ itinerary }: ItinerarySectionProps) {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Timeline position="alternate">
      {itinerary.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align={index % 2 === 0 ? 'right' : 'left'}
            variant="body2"
            color="text.secondary"
          >
            <Typography variant="h6" component="span">
              {`${t('day')} ${index + 1}`}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="secondary" sx={{ color: 'white', width: '40px', height: '40px' }}>
              <SurfIcon size={28} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Box
              component="div"
              sx={{
                fontSize: isMobile ? '12px' : '16px',
              }}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
            {item?.photoUrls && item?.photoUrls?.length > 0 && <ImageSlider images={item.photoUrls} />}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

export default ItinerarySection;
