import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  useMediaQuery,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import axios from 'axios';

import {
  TRIP_EXPENSES_QUERY,
  TRIP_EXPENSE_FILE_URL_QUERY,
  CREATE_TRIP_EXPENSE_MUTATION,
  UPDATE_TRIP_EXPENSE_MUTATION,
  DELETE_TRIP_EXPENSE_MUTATION,
} from '../../../graphql/trip-expenses';
import {
  CreateTripExpenseMutationVariables,
  CreateTripExpenseMutation,
  DeleteTripExpenseMutation,
  TripExpense,
  TripExpensesQuery,
  TripExpensesQueryVariables,
  UpdateTripExpenseMutation,
  UpdateTripExpenseMutationVariables,
  DeleteTripExpenseMutationVariables,
} from '../../../generated/graphql';
import { DateTime } from 'luxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

interface ExpensesProps {
  tripId: string;
  /**
   * Callback that allows a parent component (like the Organizer page)
   * to see the total of all expenses for admin review
   */
  onTotalExpenseChange?: (sum: number) => void;
}

const Expenses: React.FC<ExpensesProps> = ({ tripId, onTotalExpenseChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 1) Query for existing expenses
  const {
    loading: expensesLoading,
    error: expensesError,
    data,
    refetch,
  } = useQuery<TripExpensesQuery, TripExpensesQueryVariables>(TRIP_EXPENSES_QUERY, {
    variables: { tripId },
    fetchPolicy: 'network-only',
  });

  // 2) Lazy query for signed file URL
  const [getFileUrl, { loading: fileUrlLoading }] = useLazyQuery(TRIP_EXPENSE_FILE_URL_QUERY);

  // 3) Mutations for create, update, delete
  const [createExpense] = useMutation<CreateTripExpenseMutation, CreateTripExpenseMutationVariables>(
    CREATE_TRIP_EXPENSE_MUTATION
  );
  const [updateExpense] = useMutation<UpdateTripExpenseMutation, UpdateTripExpenseMutationVariables>(
    UPDATE_TRIP_EXPENSE_MUTATION
  );
  const [deleteTripExpense, { loading: deleteLoading }] = useMutation<
    DeleteTripExpenseMutation,
    DeleteTripExpenseMutationVariables
  >(DELETE_TRIP_EXPENSE_MUTATION);

  // 4) REST-based PDF upload logic
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);

  // 5) Internal modals and states
  // -- CREATE/EDIT expense modal
  const [expenseModalOpen, setExpenseModalOpen] = useState(false);
  const [editingExpense, setEditingExpense] = useState<TripExpense | null>(null); // if null -> create mode
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState<number>(0);
  const [currency, setCurrency] = useState('EUR');
  const [description, setDescription] = useState('');
  const [cancellationDeadline, setCancellationDeadline] = useState<string | null>(null);
  const [url, setUrl] = useState('');

  // -- FILE upload modal
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [targetExpenseId, setTargetExpenseId] = useState<string | null>(null);

  // -- PDF preview modal
  const [pdfViewOpen, setPdfViewOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string>('');

  // -- Delete Confirmation
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<TripExpense | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  // Access token if needed for your file uploads (REST)
  const accessToken = localStorage.getItem('accessToken') || '';

  const expenses = useMemo(() => data?.tripExpenses || [], [data?.tripExpenses]);

  // Recompute the total of all expenses and notify parent
  const computeAndNotifyTotal = useCallback(() => {
    const sum = expenses.reduce((acc, exp) => acc + (exp.amount || 0), 0);
    onTotalExpenseChange?.(sum);
  }, [expenses, onTotalExpenseChange]);

  useEffect(() => {
    if (!expensesLoading && expenses) {
      computeAndNotifyTotal();
    }
  }, [expenses, expensesLoading, computeAndNotifyTotal]);

  // ---------------- Create / Edit Expense -----------------
  const openCreateModal = () => {
    setEditingExpense(null);
    setTitle('');
    setAmount(0);
    setCurrency('EUR');
    setDescription('');
    setUrl('');
    setCancellationDeadline(null);
    setExpenseModalOpen(true);
  };

  const openEditModal = (expense: TripExpense) => {
    setEditingExpense(expense);
    setTitle(expense.title || '');
    setAmount(expense.amount || 0);
    setCurrency(expense.currency || 'EUR');
    setDescription(expense.description || '');
    setUrl(expense.url || '');
    setCancellationDeadline(
      expense.cancellationDeadline ? DateTime.fromISO(expense.cancellationDeadline).toISODate() : null
    );
    setExpenseModalOpen(true);
  };

  const handleExpenseModalClose = () => {
    setExpenseModalOpen(false);
  };

  const handleSaveExpense = async () => {
    const formattedDeadline = cancellationDeadline
      ? DateTime.fromISO(cancellationDeadline).toFormat('yyyy-MM-dd')
      : undefined;

    const baseInput = {
      title,
      amount: Number(amount || 0),
      currency,
      description,
      url: url || undefined,
      cancellationDeadline: formattedDeadline,
    };

    try {
      if (editingExpense) {
        await updateExpense({
          variables: {
            expenseId: editingExpense.id,
            input: baseInput,
          },
        });
      } else {
        await createExpense({
          variables: {
            tripId,
            input: baseInput,
          },
        });
      }
      setExpenseModalOpen(false);
      refetch();
    } catch (err: any) {
      console.error('Error saving expense:', err);
      // handle error UI
    }
  };

  // -------------- Upload / Replace PDF File ---------------
  const openFileUploadModal = (expenseId: string) => {
    setTargetExpenseId(expenseId);
    setFileUploadModalOpen(true);
    setFileToUpload(null);
    setFileUploadError(null);
  };

  const handleUploadFile = async () => {
    if (!fileToUpload) {
      setFileUploadError('No file selected');
      return;
    }
    if (!targetExpenseId) return;

    setUploadingFile(true);
    setFileUploadError(null);
    try {
      const formData = new FormData();
      formData.append('file', fileToUpload);

      // This hits your REST endpoint for uploading a file to an existing expense
      await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/trip-expenses/trip/${tripId}/expense/${targetExpenseId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setFileUploadModalOpen(false);
      refetch();
    } catch (err: any) {
      console.error('File upload error:', err);
      setFileUploadError(err.response?.data?.message || err.message || 'Upload failed');
    } finally {
      setUploadingFile(false);
    }
  };

  // ------------------ View Expense File --------------------
  const handleViewExpense = async (expenseId: string) => {
    try {
      const { data } = await getFileUrl({ variables: { expenseId } });
      const signedUrl = data?.tripExpenseFileUrl;
      if (signedUrl) {
        setPdfUrl(signedUrl);
        setPdfViewOpen(true);
      }
    } catch (error) {
      console.error('Error fetching file URL:', error);
    }
  };

  // ------------------ Delete Expense -----------------------
  const handleDeleteExpense = (expense: TripExpense) => {
    setDeleteTarget(expense);
    setDeleteError(null);
    setDeleteModalOpen(true);
  };

  const confirmDeleteExpense = async () => {
    if (!deleteTarget) return;
    setDeleteError(null);

    try {
      await deleteTripExpense({
        variables: {
          tripId,
          expenseId: deleteTarget.id,
        },
      });
      setDeleteModalOpen(false);
      refetch();
    } catch (err: any) {
      console.error('delete expense error:', err);
      setDeleteError(err.message || 'Unable to delete expense, please try again.');
    }
  };

  // ------------------ Render UI ----------------------------
  if (expensesLoading) {
    return (
      <Box textAlign="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }
  if (expensesError) {
    return <Alert severity="error">{expensesError.message}</Alert>;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {isMobile ? 'Expenses' : `Expenses for Trip #${tripId}`}
      </Typography>

      <Paper sx={{ p: 2, mb: 2 }}>
        {/* Actions row */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button startIcon={<UploadFileIcon />} variant="contained" onClick={openCreateModal}>
            Create New Expense
          </Button>
        </Box>

        {expenses.length === 0 ? (
          <Alert severity="info">No expenses recorded yet.</Alert>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Cancellation Deadline</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenses.map((exp) => (
                <TableRow key={exp.id}>
                  <TableCell>{exp.title || '(Untitled)'}</TableCell>
                  <TableCell>{exp.description || ''}</TableCell>
                  <TableCell align="right">€{exp.amount.toFixed(2)}</TableCell>
                  <TableCell>{exp.currency || 'EUR'}</TableCell>
                  <TableCell>
                    {exp.cancellationDeadline
                      ? DateTime.fromISO(exp.cancellationDeadline).toFormat('yyyy-MM-dd')
                      : '--'}
                  </TableCell>
                  <TableCell>{DateTime.fromISO(exp.createdAt).toFormat('yyyy-MM-dd HH:mm')}</TableCell>
                  <TableCell>
                    {/* View (if fileKey) */}
                    {exp.fileKey && (
                      <IconButton size="small" onClick={() => handleViewExpense(exp.id)} title="View expense file">
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    )}
                    {/* Upload file / replace file */}
                    <IconButton
                      size="small"
                      onClick={() => openFileUploadModal(exp.id)}
                      title="Upload or replace receipt"
                    >
                      <UploadFileIcon fontSize="small" />
                    </IconButton>
                    {/* Edit expense */}
                    <IconButton size="small" onClick={() => openEditModal(exp)} title="Edit expense">
                      <EditIcon fontSize="small" />
                    </IconButton>
                    {/* Delete expense */}
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDeleteExpense(exp)}
                      disabled={deleteLoading}
                      title="Delete expense"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>

      {/* -------------------- CREATE/EDIT Expense Modal -------------------- */}
      <Dialog open={expenseModalOpen} onClose={handleExpenseModalClose} fullWidth maxWidth="sm">
        <DialogTitle>{editingExpense ? 'Edit Expense' : 'Create New Expense'}</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Amount"
              type="number"
              fullWidth
              sx={{ mb: 2 }}
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Currency</InputLabel>
              <Select label="Currency" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
                {/* Add as many as your backend allows */}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="Cancellation Deadline"
                value={cancellationDeadline ? DateTime.fromISO(cancellationDeadline) : null}
                onChange={(newValue) => setCancellationDeadline(newValue ? newValue.toISODate() : null)}
                sx={{ mb: 2, width: '100%' }}
              />
            </LocalizationProvider>
            <TextField label="External URL (optional)" value={url} onChange={(e) => setUrl(e.target.value)} fullWidth />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExpenseModalClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveExpense}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* ------------------ FILE UPLOAD MODAL ------------------ */}
      <Dialog open={fileUploadModalOpen} onClose={() => setFileUploadModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Upload/Replace Receipt</DialogTitle>
        <DialogContent dividers>
          {fileUploadError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {fileUploadError}
            </Alert>
          )}
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Select File (PDF/JPG/PNG):
          </Typography>
          <input
            type="file"
            accept=".pdf,.png,.jpg,.jpeg"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setFileToUpload(e.target.files[0]);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFileUploadModalOpen(false)} disabled={uploadingFile}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleUploadFile} disabled={uploadingFile}>
            {uploadingFile ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* -------------------- PDF Preview Modal -------------------- */}
      <Dialog open={pdfViewOpen} onClose={() => setPdfViewOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Preview Expense Receipt</DialogTitle>
        <DialogContent dividers>
          {fileUrlLoading ? (
            <Box textAlign="center" mt={2}>
              <CircularProgress />
            </Box>
          ) : pdfUrl ? (
            <>
              <Box sx={{ width: '100%', height: isMobile ? 400 : 600 }}>
                <iframe title="PDF Preview" src={pdfUrl} style={{ border: 'none', width: '100%', height: '100%' }} />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={() => window.open(pdfUrl, '_blank')}>
                  Download File
                </Button>
              </Box>
            </>
          ) : (
            <Alert severity="info">No file to preview.</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPdfViewOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* ---------------- Delete Confirmation Modal ---------------- */}
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Delete Expense</DialogTitle>
        <DialogContent dividers>
          {deleteError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {deleteError}
            </Alert>
          )}
          <Typography variant="body1">
            Are you sure you want to delete <strong>{deleteTarget?.title || '(Untitled)'}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={confirmDeleteExpense} disabled={deleteLoading}>
            {deleteLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Expenses;
