import { Stack } from '@mui/material';
import { Trip } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import IconText from './IconText';
import { getDaysBetweenDates } from '../../utils/utilityFunctions';
import countryList from 'country-list';
// Icons
import GroupsIcon from '@mui/icons-material/Groups';
import EuroIcon from '@mui/icons-material/Euro';
import FmdGood from '@mui/icons-material/FmdGood';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShakaIcon from './Icons/ShakaIcon';

interface TripSummaryProps {
  trip: Trip;
}

// map code->name
const code2NameMap: Record<string, string> = countryList.getData().reduce(
  (acc, item) => {
    acc[item.code] = item.name;
    return acc;
  },
  {} as Record<string, string>
);

// fallback to code if we don't find it in map.. (e.g. code is something invalid or empty)
function getCountryName(code: string | null | undefined): string {
  if (!code) return '';
  return code2NameMap[code] ?? code;
}

const TripSummary = ({ trip }: TripSummaryProps) => {
  const { t } = useTranslation();
  const friendlyCountryName = getCountryName(trip?.country);

  return (
    <Stack direction="row" gap={2} mb={5} mt={4} justifyContent="space-between" flexWrap="wrap">
      <IconText
        icon={<CalendarMonthIcon fontSize="medium" sx={{ color: 'white' }} />}
        text={`${getDaysBetweenDates(trip.startDate, trip.endDate)} ${t('days')}`}
      />
      <IconText
        icon={<GroupsIcon fontSize="medium" sx={{ color: 'white' }} />}
        text={`${trip.numberOfPeople} ${t('people')}`}
      />
      <IconText
        icon={<FmdGood fontSize="medium" sx={{ color: 'white' }} />}
        text={`${friendlyCountryName}, ${trip.continent}`}
      />
      <IconText icon={<EuroIcon fontSize="medium" sx={{ color: 'white' }} />} text={`${trip.price.toFixed(2)}`} />
      <IconText icon={<ShakaIcon size={23} />} text={t('beginner_friendly')} />
    </Stack>
  );
};

export default TripSummary;
