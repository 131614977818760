import { SvgIcon } from '@mui/material';
import React from 'react';

interface ShakaIconProps {
  size?: number;
}

function ShakaIcon({ size = 16 }: ShakaIconProps) {
  return (
    <SvgIcon>
      <svg
        width={size}
        height={size}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
          <path
            d="M535 5106 c-81 -21 -144 -56 -212 -119 -84 -79 -129 -170 -144 -291
-15 -121 -6 -2565 10 -2696 19 -161 60 -389 91 -515 61 -239 137 -437 371
-965 l231 -520 1349 0 1349 0 5 22 c56 241 107 448 114 465 6 12 221 255 478
540 258 285 512 567 565 626 99 112 154 200 182 297 24 80 22 228 -3 310 -62
198 -242 363 -439 401 -98 18 -233 8 -317 -26 -105 -42 -165 -88 -330 -254
-82 -83 -153 -151 -157 -151 -5 0 -8 301 -8 668 0 735 1 726 -62 846 -49 92
-165 205 -258 249 -91 42 -156 57 -258 57 -134 0 -256 -45 -367 -135 l-56 -46
-51 45 c-207 181 -540 182 -745 1 l-52 -46 -58 47 c-69 56 -175 109 -251 125
-83 17 -235 7 -307 -20 -33 -13 -68 -25 -77 -28 -17 -5 -18 17 -18 365 0 335
-2 376 -19 431 -26 85 -61 140 -129 202 -119 110 -276 152 -427 115z m189
-309 c22 -12 49 -35 60 -50 21 -28 21 -32 27 -1100 4 -960 7 -1078 22 -1127
31 -103 77 -181 151 -255 195 -198 481 -230 722 -81 l42 26 24 -38 c42 -63
129 -140 198 -176 97 -51 171 -70 275 -70 152 1 278 51 389 154 77 73 123 142
153 230 13 36 26 64 30 63 5 -1 36 -14 69 -28 72 -29 198 -49 258 -40 73 11
140 28 184 46 l42 18 0 -167 0 -167 -88 -28 c-124 -39 -238 -107 -338 -200
-180 -168 -272 -357 -304 -620 -6 -48 -8 -92 -5 -97 4 -6 69 -10 150 -10 l144
0 6 58 c11 100 43 210 82 285 95 182 299 308 523 323 l85 6 285 284 c331 329
348 340 475 332 152 -10 254 -118 255 -268 0 -105 -20 -136 -277 -420 -126
-140 -390 -432 -585 -648 l-355 -393 -39 -167 -40 -167 -1134 0 -1134 0 -188
420 c-305 685 -383 965 -408 1475 -12 248 -13 2431 0 2484 17 77 89 136 165
136 23 0 57 -10 79 -23z m781 -1071 c29 -13 69 -44 93 -71 73 -82 73 -90 70
-607 l-3 -453 -27 -51 c-108 -205 -394 -202 -495 4 l-28 57 0 465 0 465 24 52
c26 60 83 116 145 144 60 27 157 25 221 -5z m832 11 c66 -25 119 -73 152 -137
l31 -63 0 -542 c0 -601 1 -596 -66 -673 -106 -125 -306 -127 -414 -5 -71 81
-70 72 -70 673 0 381 3 547 12 571 39 115 140 187 263 187 33 0 74 -5 92 -11z
m896 -20 c52 -31 93 -76 118 -132 17 -36 19 -73 19 -405 0 -362 0 -365 -24
-415 -26 -56 -87 -116 -143 -141 -62 -27 -159 -23 -226 9 -60 30 -95 65 -130
131 -21 40 -22 53 -25 389 -3 296 -1 356 13 403 20 69 80 138 150 171 70 34
181 29 248 -10z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default ShakaIcon;
