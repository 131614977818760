import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, Alert, Box, Container } from '@mui/material';
import { Membership, MembershipStatusEnum, MeQuery, MeQueryVariables, User } from '../../generated/graphql';
import { ME_QUERY } from '../../graphql/users';
import PendingMembership from '../../components/Memberships/PendingMembership/PendingMembership';
import NoMembership from '../../components/Memberships/NoMembership/NoMembership';
import { useNavigate } from 'react-router-dom';

const MembershipRequestPage: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<MeQuery, MeQueryVariables>(ME_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && data?.me?.membership) {
      const status = data.me.membership.status;
      if (status === MembershipStatusEnum.Active) {
        navigate('/membership-dashboard');
      }
    }
  }, [loading, data, navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const user = data?.me as User | null;
  if (!user) {
    return <Alert severity="error">User data not available.</Alert>;
  }

  const membership = user.membership as Membership | null;
  const membershipStatus = membership?.status;

  // Decide which membership UI to show
  if (!membership) {
    // No membership at all
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <NoMembership user={user} />
      </Container>
    );
  }

  if (membershipStatus === MembershipStatusEnum.Pending) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <PendingMembership membership={membership} />
      </Container>
    );
  }

  // Otherwise, membership might be Rejected/Inactive, treat it as "No membership" for now
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <NoMembership user={user} />
    </Container>
  );
};

export default MembershipRequestPage;
