import { Button, SxProps, Theme } from '@mui/material';

interface CustomButtonProps {
  text: string;
  onClick?: () => void;
  href?: string;
  sx?: SxProps<Theme>;
  color?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined';
  disabled?: boolean;
}

export function CustomButton({
  text,
  onClick,
  href,
  sx,
  color = 'primary',
  variant = 'contained',
  disabled = false,
  ...props
}: CustomButtonProps) {
  return (
    <Button
      variant={variant}
      color={color}
      size="large"
      href={href}
      onClick={onClick}
      disabled={disabled}
      sx={{
        color: 'black',
        borderRadius: '20px',
        boxShadow: '0px 4px 6px rgba(0,0,0,0.3)',
        '&:hover': {
          transform: 'scale(1.05)',
        },
        ...sx,
      }}
      {...props}
    >
      {text}
    </Button>
  );
}
