import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface DeleteMembershipDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDeleteMembership: () => void;
}

const DeleteMembershipDialog: React.FC<DeleteMembershipDialogProps> = ({
  isOpen,
  onClose,
  handleConfirmDeleteMembership,
}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>Delete Membership</DialogTitle>
    <DialogContent>
      <Typography variant="body1">
        Are you sure you want to delete this membership? This action cannot be undone.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirmDeleteMembership} color="secondary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteMembershipDialog;
