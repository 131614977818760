import React, { useState } from 'react';
import { Typography, Paper } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';
import { Trip, Role } from '../../../generated/graphql';
import RequestParticipationModal from '../RequestParticipationModal/RequestParticipationModal';
import { useTranslation } from 'react-i18next';
import BookNowContent from './BookNowContent';
import { DateTime } from 'luxon';

interface BookNowSectionProps {
  trip: Trip;
}

const BookNowSection: React.FC<BookNowSectionProps> = ({ trip }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const userIsMember = user && user.roles?.includes(Role.Member);

  // 1) Check if the trip has started
  const tripHasStarted = DateTime.now() >= DateTime.fromISO(trip.startDate);

  // 2) Check if the trip is full
  const isTripFull = trip.approvedParticipantsCount >= trip.numberOfPeople;

  const handleRequestJoin = () => {
    // If trip has started, do nothing or handle logic
    if (tripHasStarted) return;
    setModalOpen(true);
  };

  // 3) The text for occupancy
  const occupancyText = `${t('occupacy')}: ${
    isTripFull
      ? `${trip.numberOfPeople}/${trip.numberOfPeople}`
      : `${trip.approvedParticipantsCount}/${trip.numberOfPeople}`
  }`;

  return (
    <Paper
      sx={{
        borderRadius: 4,
        textAlign: 'center',
        boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.15)',
        mb: 3,
      }}
    >
      <Typography variant="h5" p={2} fontWeight="bold" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        {t('readyForThisAdventure')}
      </Typography>

      {/* 4) If trip started => show a "Trip Started" message */}
      {tripHasStarted ? (
        <BookNowContent text={t('this_trip_has_already_started')} buttonText={t('cannot_join_anymore')} isFull />
      ) : !user ? (
        <BookNowContent
          text={t('log_in_or_sign_up_to_request_spot')}
          buttonText={t('log_in_or_sign_up')}
          buttonHref="/auth"
          isFull={isTripFull}
          onClick={handleRequestJoin}
        />
      ) : !userIsMember ? (
        <BookNowContent
          text={t('become_a_member_to_join_this_trip')}
          buttonText={t('become_a_member')}
          buttonHref="/membership-dashboard"
          isFull={isTripFull}
          onClick={handleRequestJoin}
        />
      ) : (
        <BookNowContent
          text={t('join_this_trip_by_requesting_a_spot_below')}
          buttonText={isTripFull ? t('full_trip') : t('request_to_join')}
          isFull={isTripFull}
          occupacyText={occupancyText}
          onClick={handleRequestJoin}
        />
      )}

      {modalOpen && <RequestParticipationModal open={modalOpen} onClose={() => setModalOpen(false)} trip={trip} />}
    </Paper>
  );
};

export default BookNowSection;
