import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, Button, CircularProgress, Alert, useTheme, useMediaQuery } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { CREATE_GUIDE_REVIEW_MUTATION, CREATE_TRIP_REVIEW_MUTATION } from '../../graphql/reviews';
import {
  Trip,
  User,
  CreateTripReviewMutation,
  CreateTripReviewMutationVariables,
  CreateGuideReviewMutation,
  CreateGuideReviewMutationVariables,
} from '../../generated/graphql';
import TripLevelReviewStep, { TripReviewData } from './TripLevelReviewStep/TripLevelReviewStep';
import GuideLevelReviewStep, { GuideReviewData } from './GuideLevelReviewStep/GuideLevelReviewStep';
import WizardConfirmationStep from './WizardConfirmationStep/WizardConfirmationStep';

/**
 * Helper function that transforms numeric rating fields.
 * If a rating is "0", we pass undefined so we don't break the server's @Min(1).
 */
const optionalNum = (value: number): number | undefined => (value > 0 ? value : undefined);

// ---- Wizard STEPS
const STEPS = ['Trip Review', 'Guide Reviews', 'Confirmation'];

// ---- COMPONENT
interface TripReviewWizardProps {
  trip: Trip;
  guides: User[];
  onClose: () => void;
}

const TripReviewWizard: React.FC<TripReviewWizardProps> = ({ trip, guides, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // Step management
  const [activeStep, setActiveStep] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  // Trip-level form data
  const [tripReviewData, setTripReviewData] = useState<TripReviewData>({
    overallRating: 0,
    qualityOfAccommodation: 0,
    qualityOfSurfLessons: 0,
    timingAccuracy: 0,
    publicComment: '',
    privateComment: '',
    destinationRating: 0,
    organizationRating: 0,
  });

  // Guides-level form data
  const [guideReviewsData, setGuideReviewsData] = useState<GuideReviewData[]>(() =>
    guides.map((g) => ({
      guideId: g.id,
      overallRating: 0, // required
      ratingCommunication: 0,
      publicComment: '',
      privateComment: '',
      activitiesRating: 0,
      organizationRating: 0,
      timingAccuracy: 0,
    }))
  );

  // Mutations
  const [createTripReview, { loading: tripReviewLoading }] = useMutation<
    CreateTripReviewMutation,
    CreateTripReviewMutationVariables
  >(CREATE_TRIP_REVIEW_MUTATION);

  const [createGuideReview, { loading: guideReviewLoading }] = useMutation<
    CreateGuideReviewMutation,
    CreateGuideReviewMutationVariables
  >(CREATE_GUIDE_REVIEW_MUTATION);

  const isLoading = tripReviewLoading || guideReviewLoading;

  // -------------------------
  // WIZARD NAV
  // -------------------------
  const handleNext = () => {
    setErrorMsg(null);

    // Validate the current step
    const maybeError = validateStep(activeStep);
    if (maybeError) {
      setErrorMsg(maybeError);
      return;
    }

    // Move to next step or finalize
    if (activeStep < STEPS.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      handleSubmitAll();
    }
  };

  const handleBack = () => {
    setErrorMsg(null);
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  // -------------------------
  // VALIDATION
  // -------------------------
  const validateStep = (stepIndex: number): string | null => {
    if (stepIndex === 0) {
      // We require tripReviewData.overallRating >= 1
      if (tripReviewData.overallRating < 1) {
        return 'Please provide an Overall Rating (at least 1 star) for the trip.';
      }
    } else if (stepIndex === 1) {
      // For each guide, overallRating >= 1 if you want to require it
      for (const g of guideReviewsData) {
        if (g.overallRating < 1) {
          return 'Please provide at least 1 star for each guide’s Overall Rating.';
        }
      }
    }
    return null;
  };

  // -------------------------
  // SUBMIT
  // -------------------------
  const handleSubmitAll = async () => {
    setErrorMsg(null);
    try {
      // 1) Submit Trip-level review
      await createTripReview({
        variables: {
          input: {
            tripId: trip.id,
            // required
            overallRating: tripReviewData.overallRating,
            // optional: pass undefined if 0
            qualityOfAccommodation: optionalNum(tripReviewData.qualityOfAccommodation),
            qualityOfSurfLessons: optionalNum(tripReviewData.qualityOfSurfLessons),
            timingAccuracy: optionalNum(tripReviewData.timingAccuracy),
            publicComment: tripReviewData.publicComment.trim() || undefined,
            privateComment: tripReviewData.privateComment.trim() || undefined,
            destinationRating: optionalNum(tripReviewData.destinationRating),
            organizationRating: optionalNum(tripReviewData.organizationRating),
            // If you have extraInformationPositive, pass them similarly
          },
        },
      });

      // 2) Submit each guide's review
      for (const gReview of guideReviewsData) {
        await createGuideReview({
          variables: {
            input: {
              tripId: trip.id,
              guideId: gReview.guideId,
              overallRating: optionalNum(gReview.overallRating),
              ratingCommunication: optionalNum(gReview.ratingCommunication),
              publicComment: gReview.publicComment.trim() || undefined,
              privateComment: gReview.privateComment.trim() || undefined,
              activitiesRating: optionalNum(gReview.activitiesRating),
              organizationRating: optionalNum(gReview.organizationRating),
              timingAccuracy: optionalNum(gReview.timingAccuracy),
            },
          },
        });
      }

      // 3) done => redirect or close
      // e.g. redirect to membership dashboard
      navigate('/membership-dashboard');
    } catch (err) {
      console.error('Error creating reviews:', err);
      setErrorMsg('Something went wrong submitting your reviews. Please try again.');
    }
  };

  // -------------------------
  // RENDER STEPS
  // -------------------------
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <TripLevelReviewStep data={tripReviewData} setData={setTripReviewData} />;
      case 1:
        return <GuideLevelReviewStep guides={guides} data={guideReviewsData} setData={setGuideReviewsData} />;
      case 2:
        return <WizardConfirmationStep />;
      default:
        return null;
    }
  };

  // -------------------------
  // MAIN RETURN
  // -------------------------
  return (
    <Box sx={{ mt: isMobile ? 2 : 4 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* If there's an error in validation/submission, show it */}
      {errorMsg && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorMsg}
        </Alert>
      )}

      <Box sx={{ mt: isMobile ? 3 : 4 }}>{renderStepContent()}</Box>

      {/* Navigation buttons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: isMobile ? 2 : 4,
        }}
      >
        <Button disabled={activeStep === 0 || isLoading} onClick={handleBack} sx={{ textTransform: 'none' }}>
          Back
        </Button>
        <Box>
          {activeStep < STEPS.length - 1 ? (
            <Button variant="contained" onClick={handleNext} disabled={isLoading} sx={{ mr: 2, textTransform: 'none' }}>
              {isLoading ? <CircularProgress size={24} /> : 'Next'}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmitAll}
              disabled={isLoading}
              sx={{ mr: 2, textTransform: 'none' }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Submit Reviews'}
            </Button>
          )}
          <Button onClick={onClose} sx={{ textTransform: 'none' }} disabled={isLoading}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TripReviewWizard;
