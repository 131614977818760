import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Avatar,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ParticipantStatusEnum, PaymentStatusEnum, Role, Trip, TripParticipant, User } from '../../generated/graphql';
import ParticipantDetailModal from './ParticipantDetailModal/ParticipantDetailModal';

interface ParticipantTableProps {
  status: ParticipantStatusEnum;
  participants: TripParticipant[];
  onAccept: (userId: string) => void;
  onReject: (userId: string) => void;
  onDelete: (participantId: string) => void;
  onCancel: (userId: string) => void;
  onAddPayment: (userId: string, amount: number, status: PaymentStatusEnum) => Promise<void>;
  onUpdatePaymentStatus: (paymentId: string, newStatus: PaymentStatusEnum) => Promise<void>;
  trip: Trip;
  user: User;
}

const ParticipantTable: React.FC<ParticipantTableProps> = ({
  status,
  participants,
  onAccept,
  onReject,
  onDelete,
  onCancel,
  onAddPayment,
  onUpdatePaymentStatus,
  trip,
  user,
}) => {
  // For participant detail modal
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<TripParticipant | null>(null);

  const handleOpenDetail = (p: TripParticipant) => {
    setSelectedParticipant(p);
    setDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setDetailOpen(false);
    setSelectedParticipant(null);
  };

  const getStatusColor = (status: ParticipantStatusEnum) => {
    switch (status) {
      case ParticipantStatusEnum.CancelledByParticipant:
        return '#ed6c02'; // warning color
      case ParticipantStatusEnum.Cancelled:
        return '#d32f2f'; // error color
      case ParticipantStatusEnum.Pending:
        return '#ed6c02'; // warning color
      default:
        return 'inherit';
    }
  };

  if (!participants?.length) {
    return <Box sx={{ p: 2, mb: 2 }} />;
  }

  return (
    <>
      <Paper sx={{ p: 2, mb: 4 }} elevation={2}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {status} Participants ({participants.length})
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Final Price</TableCell>
              <TableCell>Paid So Far</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.map((p: TripParticipant) => {
              const displayName = (p.user?.firstname || '') + ' ' + (p.user?.lastname || '');
              const totalPaid =
                p?.payments?.reduce(
                  (acc, pay) => (pay.status === PaymentStatusEnum.Paid ? acc + pay.amount : acc),
                  0
                ) || 0;

              return (
                <TableRow key={p.id}>
                  <TableCell>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Avatar
                        src={p.user?.imageUrl || '/images/default_avatar.png'}
                        alt={displayName}
                        sx={{ width: 32, height: 32 }}
                      />
                      <Typography variant="body2">{displayName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      sx={{
                        color: getStatusColor(p.status),
                        fontWeight: p.status === ParticipantStatusEnum.CancelledByParticipant ? 'bold' : 'inherit',
                      }}
                    >
                      {p.status === ParticipantStatusEnum.CancelledByParticipant ? 'Cancellation Requested' : p.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">€{p.finalPrice || '-'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      sx={{
                        bgcolor: p.finalPrice && totalPaid >= p.finalPrice ? '#e8f5e9' : '#fff3e0',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        display: 'inline-block',
                      }}
                    >
                      €{totalPaid}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {/* Button to open detail modal */}
                    <Tooltip title="Manage Participant">
                      <IconButton onClick={() => handleOpenDetail(p)} size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

      {/* The Participant detail modal */}
      {selectedParticipant && (
        <ParticipantDetailModal
          open={detailOpen}
          onClose={handleCloseDetail}
          participant={selectedParticipant}
          trip={trip}
          onAccept={onAccept}
          onReject={onReject}
          onCancel={onCancel}
          onDelete={onDelete}
          onAddPayment={onAddPayment}
          onUpdatePaymentStatus={onUpdatePaymentStatus}
          userIsAdmin={user.roles.includes(Role.Admin)}
        />
      )}
    </>
  );
};

export default ParticipantTable;
